import React from 'react'
import { useNavigate } from 'react-router-dom'
function DeliveredAll() {

    const navigate = useNavigate()
    return (
        <div className='text-center container'>
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",


            }}>
                Al Delivered Items
            </div>

            <div className="col mt-2" style={{
                backgroundColor: "#0077b6",
                height: "300px",
                width: "100%",
                position: "relative"

            }} onClick={() => {
                navigate("/stitching/delieverOrder")
            }}>
                <span style={{
                    color: "white",
                    fontSize: 34,
                    fontWeight: "bold",
                    fontFamily: "initial",
                    position: "absolute",
                    top: "40%",
                    left: "40%"
                }}>
                    Stitching Delivered
                </span>
            </div>

            <div className="col mt-3" style={{
                backgroundColor: "#1d3557",
                height: "300px",
                width: "100%",
                position: "relative"

            }} onClick={() => {
                navigate("/specialRequest/delivered/all")
            }}>
                <span style={{
                    color: "white",
                    fontSize: 34,
                    fontWeight: "bold",
                    fontFamily: "initial",
                    position: "absolute",
                    top: "40%",
                    left: "40%"
                }}>
                    Special Request Delivered
                </span>
            </div>

            <div className="col mt-3" style={{
                backgroundColor: "#00b4d8",
                height: "300px",
                width: "100%",
                position: "relative"

            }} onClick={() => {
                navigate("/readyMade/delieverOrder")
            }}>
                <span style={{
                    color: "white",
                    fontSize: 34,
                    fontWeight: "bold",
                    fontFamily: "initial",
                    position: "absolute",
                    top: "40%",
                    left: "40%"
                }}>
                    Delivered ReadyMade
                </span>
            </div>

        </div>

    )
}

export default DeliveredAll