import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Trending from '../Components/Trending';
import { stitchingOrderApi } from '../Configuration/ApiIntegration';

function StitchingOrderScreen() {

    const location = useLocation()
    const product = location.state.product;
    const all = location.state.all;
    const size = location.state.size;
    const [name, setName] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [address, setAddress] = useState()
    const [requirement, setRequirement] = useState()
    const order = () => {

        if (!name) {
            alert("Please enter your name")
            return
        }
        if (!phone) {
            alert("Please enter your Phone Number")
            return
        }
        if (!email) {
            alert("Please enter your Email")
            return
        }
        if (!address) {
            alert("Please enter your delievery Address")
            return
        }
        const CompleteOrder = {
            name: name,
            phone: phone,
            email: email,
            address: address,
            requirement: requirement,
            size:size
        }

        console.log(CompleteOrder,product._id);
        stitchingOrderApi(CompleteOrder, product._id)
    }
    return (
        <div>

            <div className='row mt-2' style={{
                width: "90%",
                margin: "auto"
            }}>

                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 bg-light'>
                    <div style={{
                        width: "80%",
                        margin: "auto"
                    }}>
                        <img src={product.Images[0]} height={window.innerHeight / 2.5} width={'100%'} style={{
                            borderRadius: 10,
                            marginTop: "2%"
                        }} />

                        <div className='row row-cols-auto' style={{ justifyContent: "space-between" }}>
                            <span style={{
                                textTransform: "capitalize",
                                color: "#222222",
                                fontSize: "1rem",
                                fontWeight: '700',
                                // display: "flex",

                            }} className="col">
                                {product.Name}
                            </span>
                            <span style={{
                                textTransform: "capitalize",
                                color: "grey",
                                fontWeight: "600",
                                // marginTop: 10,
                                // lineHeight: "2",
                            }} className='col'>
                                SKU# : F-WSD001
                            </span>
                        </div>

                        <div className='row row-cols-auto mt-1' style={{ justifyContent: "space-between" }}>
                            <span style={{
                                textTransform: "capitalize",
                                color: "#222222",
                                fontSize: "1rem",
                                fontWeight: '700',
                                // display: "flex",

                            }} className="col">
                                Collection
                            </span>
                            <span style={{
                                textTransform: "capitalize",
                                color: "grey",
                                fontWeight: "600",
                                // marginTop: 10,
                                // lineHeight: "2",
                                display: "flex"
                            }} className='col'>
                                {product.main_category.Name}
                            </span>
                        </div>


                        <div className=' mt-2 row-col-auto mb-2' style={{ display: "flex" }}>
                            <span style={{
                                textTransform: "capitalize",
                                color: "#222222",
                                fontSize: "3vh",
                                fontWeight: 'bold',
                                display: 'flex'
                                // lineHeight: "22",


                            }} >
                                PKR {product.Charge_Price}.00
                            </span>
                            <span style={{
                                textTransform: "capitalize",
                                color: "darkgrey",
                                fontSize: "2vh",
                                fontWeight: '700',
                                display: 'flex',
                                // lineHeight: "",
                                marginLeft: 15,
                                marginTop: 6,
                                textDecorationLine: "line-through"

                            }} >
                                PKR{product.Actual_Price}.00
                            </span>

                        </div>

                        <div className=' mt-2 row-col-auto mb-2' style={{ justifyContent: "space-around", display: "flex" }} >
                            <span style={{
                                textTransform: "capitalize",
                                color: "#222222",
                                fontSize: "3vh",
                                fontWeight: 'bold',
                                display: 'flex',
                                textDecorationLine: "underline"
                                // lineHeight: "22",


                            }} >
                                SIZE
                            </span>
                            <span style={{
                                textTransform: "capitalize",
                                color: "grey",
                                fontSize: "3vh",
                                fontWeight: "bold",
                                display: 'flex',
                                // lineHeight: "",
                                marginLeft: 15,
                                // marginTop:6,
                                // textDecorationLine: "line-through"

                            }} >
                                {size}
                            </span>

                        </div>
                        <div style={{
                            display: "flex",
                            marginTop: 5
                        }}>

                        </div>


                    </div>
                </div>

                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>
                    <div class="mb-3 mt-3">
                        <label for="formGroupExampleInput" class="form-label fw-bold d-flex">Name</label>
                        <input type="text" class="form-control"
                            id="formGroupExampleInput"
                            placeholder="Enter your name" value={name} onChange={(event) => setName(event.target.value)} />
                    </div>
                    <div class="mb-3">
                        <label for="formGroupExampleInput2" className="form-label fw-bold d-flex">Email</label>
                        <input type="email" className="form-control" id="formGroupExampleInput2"
                            placeholder="Please enter your email" value={email} onChange={(event) => setEmail(event.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label for="formGroupExampleInput" className="form-label fw-bold d-flex">Phone</label>
                        <input type="text" className="form-control "
                            id="formGroupExampleInput"
                            placeholder="0326 4321272" value={phone} onChange={(event) => setPhone(event.target.value)} />
                    </div>
                    <div className="mb-3">
                        <label for="formGroupExampleInput2" className="form-label fw-bold d-flex">Address</label>
                        <input type="text" className="form-control" id="formGroupExampleInput2"
                            placeholder="Your delievery address" value={address} onChange={(event) => setAddress(event.target.value)} />
                    </div>
                    <label className="fw-bold d-flex">Any Requirements</label>
                    <div className="mb-3">

                        <input type="text" className="form-control"
                            id="formGroupExampleInput"
                            placeholder="Any special requirements" value={requirement} onChange={(event) => setRequirement(event.target.value)} />
                    </div>
                    <button style={{
                        //backgroundColor: 'black',
                        borderColor: "black",
                        borderWidth: 3,
                        color: "black",
                        fontSize: 24,
                        fontWeight: '700',
                        width: "98%",
                        marginTop: "5%",
                        marginBottom: "5%"
                    }} type="button" onClick={order} >
                        Place Order
                    </button>
                </div>
            </div>


            <div className='mt-5'>
                {/* <Trending trend={all} /> */}
            </div>

        </div>
    )
}

export default StitchingOrderScreen