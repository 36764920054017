import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { config } from '../../Configuration/Api'
import { OrderStatus } from '../../Configuration/ApiIntegration'
import ReactToPrint from 'react-to-print'
function StitchingOrderView() {

    const [loading, setLoading] = useState(false)
    const [presentStatus, setPresentStatus] = useState()
    const [orderAppLocation, setOrderAppLocation] = useState()
    const location = useLocation()
    const orderData = location.state.Order
    const updateTo = location.state.updateto
    const [app, setApp] = useState(false)
    //this is for select the distance [ Shop , 5km , 10km ,15km , 20km]
    const [selcDel, setSelcdel] = useState('shop')
    //this is for delivery charges  [ 100,150,200,250]
    const [delCahrges, setDelCharges] = useState(0)
    const [editReq, setEditReq] = useState()
    const [billEdit, setBillEdit] = useState(false)
    const [editLoading, setEditLoading] = useState(false)
    // this is for charges the request [1500, 2000 ,3000]
    const [charges, setCharges] = useState(0)
    const [billNotes, setBillNotes] = useState()
    const sub = orderData.Product.Charge_Price + delCahrges;
    var total = sub + parseInt(charges)
    const parse = localStorage.getItem("data")
    const getUser = JSON.parse(parse)
    const date = new Date(orderData.createdAt)

    const componentRef = useRef()
    useEffect(() => {
        if (orderData.From == "App") {
            setApp(true)
            console.log(orderData.Order);
            const type = typeof (orderData.Order);
            console.log(type);
            type == "object" ? setOrderAppLocation(orderData.Order) : setOrderAppLocation(JSON.parse(orderData.Order))
            return
        }
    }, [])

    const showStatus = async (id) => {
        setLoading(true)
        console.log(id);

        await fetch(`${config.baseurl}/stitching/order/${updateTo}/${id}`,
        ).then(
            (response) => response.json()
        ).catch((err) => {
            // alert("something went wrong. Please try again")
            console.log(err);
        }).then(responseData => {
            console.log(responseData)
            setLoading(false)
            // console.log(responseData.order.Status);
            // setPresentStatus(responseData.order.Status)


        })
    }

    const updateRequiremt = async (source) => {
        setEditLoading(true)

        console.log(orderData._id);
        //${config.baseurl}
        await fetch(`${config.baseurl}/stitching/order/update/${orderData._id}`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    // requirement: source == "App" ? orderAppLocation : orderData.Order
                    note: editReq
                })
            }).then(
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again")
                console.log(err);
            }).then(responseData => {
                // console.log(responseData);
                if (responseData.success == true) {

                    setEditLoading(false)
                    return
                }
                else {
                    setEditLoading(false)
                    alert("Error! Requirement is not updated")
                }

            })
    }

    const orderCancel = async (source) => {
        setEditLoading(true)
        console.log(orderData._id);
        //${config.baseurl}
        await fetch(`${config.baseurl}/stitching/order/cancel/${orderData._id}`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    note: editReq,
                })
            }).then(
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again")
                console.log(err);
            }).then(responseData => {
                // console.log(responseData);
                if (responseData.success == true) {

                    setEditLoading(false)
                    return
                }
                else {
                    setEditLoading(false)
                    alert("Error! Requirement is not updated")
                }

            })
    }
    const updateBill = async (source) => {
        setEditLoading(true)

        console.log(orderData._id);
        //${config.baseurl}
        await fetch(`${config.baseurl}/stitching/order/update/bill/${orderData._id}`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    // requirement: source == "App" ? orderAppLocation : orderData.Order
                    note: billNotes,
                    dc: delCahrges,
                    charges: charges,
                    product: orderData.Product.Charge_Price

                })
            }).then(
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again . Bill is not updated")
                console.log(err);
            }).then(responseData => {
                // console.log(responseData);
                if (responseData.success == true) {

                    setEditLoading(false)
                    return
                }
                else {
                    setEditLoading(false)
                    alert("Error! Bill is not updated")
                }

            })
    }

    console.log(orderData);
    return (
        <div >
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                Stitching Order Details
            </div>

            <nav aria-label="breadcrumb" className='container bg-light p-1 mt-1'>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/dashboard">Home</a></li>
                    <li class="breadcrumb-item"><a href="/stitching">Stitching Dashboard</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {orderData.From == "App" ? orderData.User.Name : orderData.Order.name}
                        Order</li>
                </ol>
            </nav>

            <div className='container mt-1' style={{
                backgroundColor: "black",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                // textAlign: "center"


            }}>
                Product Details
            </div>
            <div className='row align-items-center container mt-2 ' style={{
                margin: "auto"
            }}>

                <div className='col'>
                    <table className=" table ">
                        <thead>
                            <tr>
                                <th >Design Name</th>
                                <th >Design No</th>
                            </tr>
                            <tr>
                                <td>{orderData.Product.Name}</td>
                                <td>{orderData.Product.Design_SKU}</td>
                            </tr>
                            <tr>
                                <th >Cost</th>
                                <th >Product Gender</th>
                            </tr>
                            <tr>
                                <td>{orderData.Product.Charge_Price}<strong> Rs</strong></td>
                                <td>{orderData.Product.main_category.general_category.Name}</td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='col'>
                    <img src={orderData.Product.Images[0]} className="border" width={"100%"} height={200} />
                </div>
            </div>


            <table className="container table mt-1">
                <thead>
                    <tr>
                        {/* <th>Order Number</th> */}
                        <th>DesignType</th>
                        <th >Fabric Required</th>
                        <th>From</th>
                        <th>Status</th>

                    </tr>
                    <tr>

                        {/* <td>{orderData.OrderNumber}</td> */}
                        <td>{orderData.Product.DesignType}</td>
                        <td>{orderData.Product.FabricRequired}</td>
                        <td>{orderData.From}</td>
                        <td>{orderData.Status}</td>
                    </tr>

                    <tr>
                        <th>
                            <b>
                                FOR CANCEL ORDER
                            </b>
                        </th>
                        <th>
                            <b>
                                ORDER DATE
                            </b>
                        </th>
                    </tr>

                    <tr>
                        <td>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                style={{ backgroundColor: "red", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                data-bs-target="#cancel" >
                                Cancel
                            </button>

                            <div class="modal fade" id="cancel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Please write Reason for cancel order</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">

                                            {/* <input style={{
                                                            width: "100%"
                                                        }} /> */}
                                            <textarea style={{
                                                width: "100%"
                                            }} value={editReq}
                                                multiple={true}
                                                onChange={(val) => setEditReq(val.target.value)}>

                                            </textarea>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary"

                                                onClick={() => {

                                                    orderCancel("App")
                                                }}>
                                                {
                                                    editLoading ? "Loading ..." : "Click Cancel"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        
                        <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                    </tr>
                </thead>
            </table>

            <div className='container mt-1' style={{
                backgroundColor: "black",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                // textAlign: "center"


            }}>
                User Details
            </div>

            {
                app ?
                    <div>
                        <table className=" table container table-striped ">
                            <thead>
                                <tr>
                                    <th >Customer Name</th>
                                    <th >Customer Gender</th>
                                </tr>
                                <tr>
                                    <td>{orderData.User.Name}</td>
                                    <td>{orderData.User.Gender}</td>
                                </tr>
                                <tr>
                                    <th >Email</th>
                                    <th >Phone</th>
                                </tr>
                                <tr>
                                    <td>{orderData.User.Email}</td>
                                    <td>{orderData.User.Phone}</td>
                                </tr>
                            </thead>
                        </table>

                        <div className='container mt-1' style={{
                            backgroundColor: "black",
                            height: "60px",
                            width: "100%",
                            color: "white",
                            fontSize: 34,
                            fontWeight: "bold",
                            fontFamily: "initial",
                            // textAlign: "center"


                        }}>
                            Order description
                        </div>

                        <table className=" table container table-striped ">
                            <thead>
                                <tr>
                                    <th >Order Size</th>
                                    <th >Order Number</th>
                                </tr>
                                <tr>
                                    <td>{orderAppLocation.Size}</td>
                                    <td>{orderData.OrderNumber}  </td>
                                </tr>
                                <tr>
                                    <th >Address</th>
                                    <th >Requirement</th>
                                </tr>
                                <tr>
                                    <td>{orderAppLocation.Address}</td>
                                    <td>
                                        {orderAppLocation.Requirements}
                                    </td>
                                </tr>
                                <tr>
                                    <th >Notes</th>
                                </tr>
                                <tr>
                                    <td >{orderData.Note}{"\n"}
                                        <br />
                                        {/* Start Requirement editing */}
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                            style={{ backgroundColor: "red", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                            data-bs-target="#staticBackdro1" >
                                            Add Note
                                        </button>

                                        <div class="modal fade" id="staticBackdro1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Requirement Update</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">

                                                        {/* <input style={{
                                                            width: "100%"
                                                        }} /> */}
                                                        <textarea style={{
                                                            width: "100%"
                                                        }} value={editReq}
                                                            multiple={true}
                                                            onChange={(val) => setEditReq(val.target.value)}>

                                                        </textarea>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary"
                                                            // data-bs-dismiss="modal"
                                                            onClick={() => {
                                                                // showStatus(orderData._id)
                                                                updateRequiremt("App")
                                                            }}>
                                                            {
                                                                editLoading ? "Loading ..." : "Save"
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></td>
                                </tr>

                                <tr>
                                    <th >Measurement</th>

                                </tr>
                                <tr>
                                    <td>{JSON.stringify(orderAppLocation.CustomeMeasurement)}</td>
                                </tr>
                                <h3>Location</h3>
                                <tr>
                                    <th >Longitude</th>
                                    <th >Latitude</th>
                                </tr>
                                <tr>
                                    <td>{orderAppLocation.Location.longitude}</td>
                                    <td>{orderAppLocation.Location.latitude}</td>
                                </tr>
                            </thead>
                        </table>
                        {
                            orderData.Status == "Confirmed" ?
                                <div>
                                    <div className='container mt-3 mb-3' style={{
                                        backgroundColor: "green",
                                        height: "60px",
                                        width: "100%",
                                        color: "white",
                                        fontSize: 34,
                                        fontWeight: "bold",
                                        fontFamily: "initial",
                                        // textAlign: "center"


                                    }}>
                                        Payment

                                        {
                                            billEdit ?
                                                <div style={{ position: "relative" }}>


                                                    <button style={{ position: "absolute", right: "10%", top: -50, backgroundColor: "blue", color: "white" }}
                                                        onClick={() => {
                                                            updateBill()
                                                        }}>
                                                        {editLoading ? 'Saving ...' : "Saved"}
                                                    </button>
                                                    <button style={{ position: "absolute", right: "30%", top: -50, backgroundColor: "tomato", color: "white" }}
                                                        onClick={() => {
                                                            setBillEdit(false)
                                                        }}>
                                                        Cancel
                                                    </button>
                                                </div>
                                                :
                                                //check if bill is edit till now or not
                                                //if yes then only allow to generate the slip

                                                getUser.GenerateBill ?
                                                    orderData.Edit ?
                                                        // <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                        //     onClick={() => {

                                                        //     }}>
                                                        //     Generate bill
                                                        // </button>
                                                        <ReactToPrint
                                                            trigger={() =>
                                                                <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}>
                                                                    Generate bill
                                                                </button>
                                                            }
                                                            content={() => componentRef.current}
                                                        />
                                                        :
                                                        <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                            onClick={() => {
                                                                setBillEdit(true)
                                                            }}>
                                                            Edit
                                                        </button>
                                                    :
                                                    <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                    >
                                                        UnAuthorize
                                                    </button>



                                        }
                                    </div>
                                    {/* this is for receipt  */}
                                    {
                                        orderData.Edit ?

                                            <div ref={componentRef} style={{}} >
                                                {/* Receipt Header */}
                                                <p className='text-center m-0'>
                                                    <b style={{ fontSize: 20 }}>FashionWise</b>
                                                    <br />
                                                    Fit ha tou , hit hai
                                                </p>
                                                <p className='text-center m-0'>
                                                    <b>Address:</b> 70 D Khyber Block Main wahdat Road
                                                    <br />
                                                    Allama Iqbal Town , Lahore
                                                    <br />
                                                    0326 4321272
                                                </p>
                                                {/* Customer details */}
                                                <table className='table table-borderless table-sm m-0'>
                                                    <tbody>
                                                        <tr>
                                                            <td>Customer</td>
                                                            <td><b  style={{textTransform:"capitalize"}}>{orderData.User.Name}</b></td>
                                                        </tr>
                                                 
                                                        <tr>
                                                            <td>Order</td>
                                                            <td>{orderData.OrderNumber}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <table className='table table-bordered table-sm  ' >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Service
                                                            </th>
                                                            <th>
                                                                Code
                                                            </th>
                                                            <th>
                                                                Price
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{orderData.Product.Name}</td>
                                                            <td>{orderData.Product.Design_SKU}</td>
                                                            <td>{orderData.Product.Charge_Price}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                DC
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                {orderData.DC}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Extra
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                {orderData.Charges}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                               
                                                <table className='table table-borderless table-sm text-center'>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                Sub Total
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                {orderData.TotalBill}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Discount
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                0.0
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b style={{ fontSize: 22 }}>
                                                                    Total
                                                                </b>
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                <b style={{ fontSize: 20 }}>
                                                                    {orderData.TotalBill}
                                                                </b>

                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                <div className='text-center'>
                                                    <b >
                                                        ************
                                                    </b>
                                                </div>

                                                <div>
                                                    <p style={{ textTransform: "capitalize" }}>
                                                        <b>Extra Charges: </b>
                                                        {orderData.BillNote}
                                                    </p>
                                                    <p className='text-center'>
                                                        <b style={{ fontSize: 20 }}>Thank You!</b>

                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            ''
                                    }
                                    <table className=" table container table-striped  table-bordered border-primary ">
                                        <thead>

                                            <tr>
                                                <th >Design  Charges
                                                    {"\n\n"}
                                                    {/* <b style={{ backgroundColor: "green", color: "wheat", padding: "1%" }}>
                                            {orderData.Charges} Rs
                                        </b> */}
                                                </th>
                                                <th >Delivery Charges
                                                    {"\n\n"}
                                                    <b style={{ backgroundColor: "green", color: "wheat", padding: "1%" }}>
                                                        {orderData.DC} Rs
                                                    </b>
                                                </th>
                                                <th>
                                                    Extra Charges
                                                    <b style={{ backgroundColor: "green", color: "wheat", padding: "1%" }}>
                                                        {orderData.Charges} Rs
                                                    </b>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>{orderData.Product.Charge_Price}<strong> Rs</strong></td>
                                                <td>
                                                    {
                                                        billEdit ?
                                                            // <input value={charges} onChange={(val) => setCharges(val.target.value)} />
                                                            <ul>
                                                                {/* shop*/}
                                                                {
                                                                    selcDel == "shop" ?
                                                                        <li onClick={() => { setDelCharges(0); setSelcdel('shop') }} style={{ cursor: "pointer" }} >
                                                                            Shop 0
                                                                            <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                                Selected {selcDel}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => { setDelCharges(0); setSelcdel('shop') }} style={{ cursor: "pointer" }} >Shop 0</li>
                                                                }

                                                                {
                                                                    selcDel == "10km" ?
                                                                        <li onClick={() => { setDelCharges(150); setSelcdel('10km') }} style={{ cursor: "pointer" }} >
                                                                            10Km  <b style={{ color: "green" }}>150Rs</b>
                                                                            <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                                Less then {selcDel}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => { setDelCharges(150); setSelcdel('10km') }} style={{ cursor: "pointer" }} >10KM <b style={{ color: "green" }}>150Rs</b></li>
                                                                }

                                                                {
                                                                    selcDel == "15km" ?
                                                                        <li onClick={() => { setDelCharges(200); setSelcdel('15km') }} style={{ cursor: "pointer" }} >
                                                                            15Km  <b style={{ color: "green" }}>200Rs</b>
                                                                            <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                                Less then {selcDel}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => { setDelCharges(200); setSelcdel('15km') }} style={{ cursor: "pointer" }} >15KM <b style={{ color: "green" }}>200Rs</b></li>
                                                                }

                                                                {
                                                                    selcDel == "20km" ?
                                                                        <li onClick={() => { setDelCharges(250); setSelcdel('20km') }} style={{ cursor: "pointer" }} >
                                                                            20Km  <b style={{ color: "green" }}>250Rs</b>
                                                                            <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                                Less then {selcDel}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => { setDelCharges(250); setSelcdel('20km') }} style={{ cursor: "pointer" }} >20KM <b style={{ color: "green" }}>250Rs</b></li>
                                                                }




                                                            </ul>
                                                            :
                                                            <p>
                                                                {orderData.DC} Rs
                                                            </p>
                                                    }</td>

                                                <td>
                                                    {
                                                        billEdit ?
                                                            <input value={charges} type='number' onChange={(val) => setCharges(val.target.value)} />
                                                            :
                                                            <b>
                                                                {
                                                                    orderData.Charges
                                                                }
                                                            </b>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Extra Charges Comment
                                                </th>
                                                <td>
                                                    {
                                                        billEdit ?
                                                            <div>
                                                                <b>
                                                                    {orderData.BillNote}
                                                                </b>
                                                                <textarea value={billNotes} onChange={(val) => setBillNotes(val.target.value)}
                                                                    placeholder={orderData.BillNote} style={{ width: "100%" }} />
                                                            </div>
                                                            :
                                                            <b>
                                                                {
                                                                    orderData.BillNote
                                                                }
                                                            </b>
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Sub total
                                                </th>
                                                <td>
                                                    {
                                                        billEdit ?
                                                            <b>
                                                                {orderData.Product.Charge_Price}+ {delCahrges} + {charges}
                                                                = {total}
                                                            </b>
                                                            :
                                                            <b>
                                                                {orderData.Product.Charge_Price} + {orderData.DC} + {orderData.Charges}
                                                                = {orderData.TotalBill}
                                                            </b>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Discount
                                                </th>
                                                <td>
                                                    <b>0.0</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Total Bill
                                                </th>
                                                <td>
                                                    <b>
                                                        {orderData.TotalBill}
                                                    </b>
                                                </td>
                                            </tr>

                                        </thead>
                                    </table>
                                </div>
                                :
                                // only show bill in completed state
                                orderData.Status == "Completed" ?
                                    <div>
                                        <div className='container mt-3 mb-3' style={{
                                            backgroundColor: "green",
                                            height: "60px",
                                            width: "100%",
                                            color: "white",
                                            fontSize: 34,
                                            fontWeight: "bold",
                                            fontFamily: "initial",
                                            // textAlign: "center"


                                        }}>
                                            Payment
                                        </div>
                                        <table className=" table container table-striped  table-bordered border-primary ">
                                            <thead>

                                                <tr>
                                                    <th>Design  Charges  </th>
                                                    <th>Delivery Charges </th>
                                                    <th>Extra Charges  </th>
                                                </tr>
                                                <tr>
                                                    <td>{orderData.Product.Charge_Price}</td>
                                                    <td>{orderData.DC}</td>
                                                    <td>{orderData.Charges}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Extra Charges Comment
                                                    </th>
                                                    <td>
                                                        {
                                                            billEdit ?
                                                                <div>
                                                                    <b>
                                                                        {orderData.BillNote}
                                                                    </b>
                                                                    <textarea value={billNotes} onChange={(val) => setBillNotes(val.target.value)}
                                                                        placeholder={orderData.BillNote} style={{ width: "100%" }} />
                                                                </div>
                                                                :
                                                                <b>
                                                                    {
                                                                        orderData.BillNote
                                                                    }
                                                                </b>
                                                        }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>
                                                        Sub total
                                                    </th>
                                                    <td>
                                                        {
                                                            billEdit ?
                                                                <b>
                                                                    {orderData.Product.Charge_Price}+ {delCahrges} + {charges}
                                                                    = {total}
                                                                </b>
                                                                :
                                                                <b>
                                                                    {orderData.Product.Charge_Price} + {orderData.DC} + {orderData.Charges}
                                                                    = {orderData.TotalBill}
                                                                </b>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Discount
                                                    </th>
                                                    <td>
                                                        <b>0.0</b>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>
                                                        Total Bill
                                                    </th>
                                                    <td>
                                                        <b>
                                                            {orderData.TotalBill}
                                                        </b>
                                                    </td>
                                                </tr>

                                            </thead>
                                        </table>
                                    </div>
                                    :
                                    ""
                        }

                        <div className='row'>
                            {
                                orderData.Status == 'Confirmed' ?
                                    getUser.GenerateBill ?
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                            style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                            data-bs-target="#staticBackdro" onClick={() => {
                                                showStatus(orderData._id)
                                            }}>
                                            {updateTo}
                                        </button>
                                        :
                                        <button type="button" class="btn btn-primary"
                                            style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}>
                                            Can't update Status
                                        </button>
                                    :
                                    orderData.Status == "Completed" ?
                                        getUser.CollectBill ?
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                                data-bs-target="#staticBackdro" onClick={() => {
                                                    showStatus(orderData._id)
                                                }}>
                                                {updateTo}
                                            </button>
                                            :
                                            <button type="button" class="btn btn-primary"
                                                style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}>
                                                Can't update Status
                                            </button>
                                        :
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                            style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                            data-bs-target="#staticBackdro" onClick={() => {
                                                showStatus(orderData._id)
                                            }}>
                                            {updateTo}
                                        </button>

                            }

                            <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Order Status</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">

                                            {
                                                loading ?
                                                    <span>
                                                        Loading...
                                                    </span>
                                                    :
                                                    <span>
                                                        <b>
                                                            Order has been updated From
                                                            <strong> {orderData.Status}</strong>
                                                            to {updateTo}
                                                        </b>
                                                        <br />
                                                        <b>Order Number <strong>{orderData.OrderNumber}</strong></b>
                                                        <br />
                                                        {/* <a href='/stitching/confirmOrder'>
                                                            Go to confirm {orderData.OrderNumber}
                                                        </a> */}
                                                    </span>

                                            }
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </div>
                    :

                    <div>

                        <table className=" table container table-striped ">
                            <thead>
                                <tr>
                                    <th >Customer Name</th>
                                    <th >Customer Gender</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.name}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th >Email</th>
                                    <th >Phone</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.email}</td>
                                    <td>{orderData.Order.phone}</td>
                                </tr>
                            </thead>
                        </table>

                        <div className='container mt-1' style={{
                            backgroundColor: "black",
                            height: "60px",
                            width: "100%",
                            color: "white",
                            fontSize: 34,
                            fontWeight: "bold",
                            fontFamily: "initial",
                            // textAlign: "center"


                        }}>
                            Order description
                        </div>

                        <table className=" table container table-striped ">
                            <thead>
                                <tr>
                                    <th >Order Size</th>
                                    <th >Order Number</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.size}</td>
                                    <td>{orderData.OrderNumber}</td>
                                </tr>
                                <tr>
                                    <th >Address</th>
                                    <th >Requirement</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.address}</td>
                                    <td>
                                        {orderData.Order.requirement}
                                        <br />

                                    </td>
                                </tr>
                                <tr>
                                    <th >Notes</th>
                                </tr>
                                <tr>
                                    <td >{orderData.Note}
                                        <br />
                                        {/* Start Requirement editing */}
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                            style={{ backgroundColor: "red", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                            data-bs-target="#staticBackdro1" >
                                            Add Note
                                        </button>

                                        <div class="modal fade" id="staticBackdro1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Requirement Update</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">

                                                        {/* <input style={{
                                                            width: "100%"
                                                        }} /> */}
                                                        <textarea style={{
                                                            width: "100%"
                                                        }} value={editReq}
                                                            multiple={true}
                                                            onChange={(val) => setEditReq(val.target.value)}>

                                                        </textarea>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary"
                                                            // data-bs-dismiss="modal"
                                                            onClick={() => {
                                                                // showStatus(orderData._id)
                                                                updateRequiremt("Web")
                                                            }}>
                                                            {
                                                                editLoading ? "Loading ..." : "Save"
                                                            }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></td>
                                </tr>

                            </thead>
                        </table>
                        {
                            orderData.Status == "Confirmed" ?
                                <div>
                                    <div className='container mt-3 mb-3' style={{
                                        backgroundColor: "green",
                                        height: "60px",
                                        width: "100%",
                                        color: "white",
                                        fontSize: 34,
                                        fontWeight: "bold",
                                        fontFamily: "initial",
                                        // textAlign: "center"


                                    }}>
                                        Payment

                                        {/* {
                                            billEdit ?
                                                <div style={{ position: "relative" }}>


                                                    <button style={{ position: "absolute", right: "10%", top: -50, backgroundColor: "blue", color: "white" }}
                                                        onClick={() => {
                                                            updateBill()
                                                        }}>
                                                        {editLoading ? 'Saving ...' : "Saved"}
                                                    </button>
                                                    <button style={{ position: "absolute", right: "30%", top: -50, backgroundColor: "tomato", color: "white" }}
                                                        onClick={() => {
                                                            setBillEdit(false)
                                                        }}>
                                                        Cancel
                                                    </button>
                                                </div>
                                                :
                                                //check if bill is edit till now or not
                                                //if yes then only allow to generate the slip
                                                orderData.Edit ?
                                                    <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                        onClick={() => {
                                                            // setBillEdit(true)
                                                        }}>
                                                        Generate bill
                                                    </button>
                                                    :
                                                    <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                        onClick={() => {
                                                            setBillEdit(true)
                                                        }}>
                                                        Edit
                                                    </button>
                                        } */}

                                        {
                                            billEdit ?
                                                <div style={{ position: "relative" }}>


                                                    <button style={{ position: "absolute", right: "10%", top: -50, backgroundColor: "blue", color: "white" }}
                                                        onClick={() => {
                                                            updateBill()
                                                        }}>
                                                        {editLoading ? 'Saving ...' : "Saved"}
                                                    </button>
                                                    <button style={{ position: "absolute", right: "30%", top: -50, backgroundColor: "tomato", color: "white" }}
                                                        onClick={() => {
                                                            setBillEdit(false)
                                                        }}>
                                                        Cancel
                                                    </button>
                                                </div>
                                                :
                                                //check if bill is edit till now or not
                                                //if yes then only allow to generate the slip

                                                getUser.GenerateBill ?
                                                    orderData.Edit ?
                                                        // <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                        //     onClick={() => {

                                                        //     }}>
                                                        //     Generate bill
                                                        // </button>
                                                        <ReactToPrint
                                                            trigger={() =>
                                                                <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}>
                                                                    Generate bill
                                                                </button>
                                                            }
                                                            content={() => componentRef.current}
                                                        />
                                                        :
                                                        <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                            onClick={() => {
                                                                setBillEdit(true)
                                                            }}>
                                                            Edit
                                                        </button>
                                                    :
                                                    <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                    >
                                                        UnAuthorize
                                                    </button>



                                        }
                                    </div>
                                          {/* this is for receipt  */}
                                          {
                                        orderData.Edit ?

                                            <div ref={componentRef} style={{}} >
                                                {/* Receipt Header */}
                                                <p className='text-center m-0'>
                                                    <b style={{ fontSize: 20 }}>FashionWise</b>
                                                    <br />
                                                    Fit ha tou , hit hai
                                                </p>
                                                <p className='text-center m-0'>
                                                    <b>Address:</b> 70 D Khyber Block Main wahdat Road
                                                    <br />
                                                    Allama Iqbal Town , Lahore
                                                    <br />
                                                    0326 4321272
                                                </p>
                                                {/* Customer details */}
                                                <table className='table table-borderless table-sm m-0'>
                                                    <tbody>
                                                        <tr>
                                                            <td>Customer</td>
                                                            <td><b style={{textTransform:"capitalize"}}>{orderData.Order.name}</b></td>
                                                        </tr>
                                                        
                                                        <tr>
                                                            <td>Order</td>
                                                            <td>{orderData.OrderNumber}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {/* <b>
                                            ************
                                        </b> */}
                                                <table className='table table-bordered table-sm  ' >
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                Service
                                                            </th>
                                                            <th>
                                                                Code
                                                            </th>
                                                            <th>
                                                                Price
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{orderData.Product.Name}</td>
                                                            <td>{orderData.Product.Design_SKU}</td>
                                                            <td>{orderData.Product.Charge_Price}</td>
                                                        </tr>

                                                        <tr>
                                                            <td>
                                                                DC
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                {orderData.DC}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Extra
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                {orderData.Charges}
                                                            </td>
                                                        </tr>


                                                    </tbody>
                                                </table>
                                                {/* <b style={{ margin: "auto" }}>
                                            ______________
                                        </b> */}
                                                <table className='table table-borderless table-sm text-center'>
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                Sub Total
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                {orderData.TotalBill}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Discount
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                0.0
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <b style={{ fontSize: 22 }}>
                                                                    Total
                                                                </b>
                                                            </td>
                                                            <td>

                                                            </td>
                                                            <td>
                                                                <b style={{ fontSize: 20 }}>
                                                                    {orderData.TotalBill}
                                                                </b>

                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                                <div className='text-center'>
                                                    <b >
                                                        ************
                                                    </b>
                                                </div>

                                                <div>
                                                    <p style={{ textTransform: "capitalize" }}>
                                                        <b>Extra Charges: </b>
                                                        {orderData.BillNote}
                                                    </p>
                                                    <p className='text-center'>
                                                        <b style={{ fontSize: 20 }}>Thank You!</b>

                                                    </p>
                                                </div>
                                            </div>
                                            :
                                            ''
                                    }
                                    <table className=" table container table-striped  table-bordered border-primary ">
                                        <thead>

                                            <tr>
                                                <th >Design  Charges
                                                    {"\n\n"}
                                                    {/* <b style={{ backgroundColor: "green", color: "wheat", padding: "1%" }}>
                                            {orderData.Charges} Rs
                                        </b> */}
                                                </th>
                                                <th >Delivery Charges
                                                    {"\n\n"}
                                                    <b style={{ backgroundColor: "green", color: "wheat", padding: "1%" }}>
                                                        {orderData.DC} Rs
                                                    </b>
                                                </th>
                                                <th>
                                                    Extra Charges
                                                    <b style={{ backgroundColor: "green", color: "wheat", padding: "1%" }}>
                                                        {orderData.Charges} Rs
                                                    </b>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td>{orderData.Product.Charge_Price}<strong> Rs</strong></td>
                                                <td>
                                                    {
                                                        billEdit ?
                                                            // <input value={charges} onChange={(val) => setCharges(val.target.value)} />
                                                            <ul>
                                                                {/* shop*/}
                                                                {
                                                                    selcDel == "shop" ?
                                                                        <li onClick={() => { setDelCharges(0); setSelcdel('shop') }} style={{ cursor: "pointer" }} >
                                                                            Shop 0
                                                                            <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                                Selected {selcDel}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => { setDelCharges(0); setSelcdel('shop') }} style={{ cursor: "pointer" }} >Shop 0</li>
                                                                }

                                                                {
                                                                    selcDel == "10km" ?
                                                                        <li onClick={() => { setDelCharges(150); setSelcdel('10km') }} style={{ cursor: "pointer" }} >
                                                                            10Km  <b style={{ color: "green" }}>150Rs</b>
                                                                            <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                                Less then {selcDel}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => { setDelCharges(150); setSelcdel('10km') }} style={{ cursor: "pointer" }} >10KM <b style={{ color: "green" }}>150Rs</b></li>
                                                                }

                                                                {
                                                                    selcDel == "15km" ?
                                                                        <li onClick={() => { setDelCharges(200); setSelcdel('15km') }} style={{ cursor: "pointer" }} >
                                                                            15Km  <b style={{ color: "green" }}>200Rs</b>
                                                                            <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                                Less then {selcDel}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => { setDelCharges(200); setSelcdel('15km') }} style={{ cursor: "pointer" }} >15KM <b style={{ color: "green" }}>200Rs</b></li>
                                                                }

                                                                {
                                                                    selcDel == "20km" ?
                                                                        <li onClick={() => { setDelCharges(250); setSelcdel('20km') }} style={{ cursor: "pointer" }} >
                                                                            20Km  <b style={{ color: "green" }}>250Rs</b>
                                                                            <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                                Less then {selcDel}
                                                                            </p>
                                                                        </li>
                                                                        :
                                                                        <li onClick={() => { setDelCharges(250); setSelcdel('20km') }} style={{ cursor: "pointer" }} >20KM <b style={{ color: "green" }}>250Rs</b></li>
                                                                }




                                                            </ul>
                                                            :
                                                            <p>
                                                                {orderData.DC} Rs
                                                            </p>
                                                    }</td>

                                                <td>
                                                    {
                                                        billEdit ?
                                                            <input value={charges} type='number' onChange={(val) => setCharges(val.target.value)} />
                                                            :
                                                            <b>
                                                                {
                                                                    orderData.Charges
                                                                }
                                                            </b>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Extra Charges Comment
                                                </th>
                                                <td>
                                                    {
                                                        billEdit ?
                                                            <div>
                                                                <b>
                                                                    {orderData.BillNote}
                                                                </b>
                                                                <textarea value={billNotes} onChange={(val) => setBillNotes(val.target.value)}
                                                                    placeholder={orderData.BillNote} style={{ width: "100%" }} />
                                                            </div>
                                                            :
                                                            <b>
                                                                {
                                                                    orderData.BillNote
                                                                }
                                                            </b>
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Sub total
                                                </th>
                                                <td>
                                                    {
                                                        billEdit ?
                                                            <b>
                                                                {orderData.Product.Charge_Price}+ {delCahrges} + {charges}
                                                                = {total}
                                                            </b>
                                                            :
                                                            <b>
                                                                {orderData.Product.Charge_Price} + {orderData.DC} + {orderData.Charges}
                                                                = {orderData.TotalBill}
                                                            </b>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    Discount
                                                </th>
                                                <td>
                                                    <b>0.0</b>
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>
                                                    Total Bill
                                                </th>
                                                <td>
                                                    <b>
                                                        {orderData.TotalBill}
                                                    </b>
                                                </td>
                                            </tr>

                                        </thead>
                                    </table>
                                </div>
                                :
                                // only show bill in completed state
                                orderData.Status == "Completed" ?
                                    <div>
                                        <div className='container mt-3 mb-3' style={{
                                            backgroundColor: "green",
                                            height: "60px",
                                            width: "100%",
                                            color: "white",
                                            fontSize: 34,
                                            fontWeight: "bold",
                                            fontFamily: "initial",
                                            // textAlign: "center"


                                        }}>
                                            Payment
                                        </div>
                                        <table className=" table container table-striped  table-bordered border-primary ">
                                            <thead>

                                                <tr>
                                                    <th>Design  Charges  </th>
                                                    <th>Delivery Charges </th>
                                                    <th>Extra Charges  </th>
                                                </tr>
                                                <tr>
                                                    <td>{orderData.Product.Charge_Price}</td>
                                                    <td>{orderData.DC}</td>
                                                    <td>{orderData.Charges}</td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Extra Charges Comment
                                                    </th>
                                                    <td>
                                                        {
                                                            billEdit ?
                                                                <div>
                                                                    <b>
                                                                        {orderData.BillNote}
                                                                    </b>
                                                                    <textarea value={billNotes} onChange={(val) => setBillNotes(val.target.value)}
                                                                        placeholder={orderData.BillNote} style={{ width: "100%" }} />
                                                                </div>
                                                                :
                                                                <b>
                                                                    {
                                                                        orderData.BillNote
                                                                    }
                                                                </b>
                                                        }
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>
                                                        Sub total
                                                    </th>
                                                    <td>
                                                        {
                                                            billEdit ?
                                                                <b>
                                                                    {orderData.Product.Charge_Price}+ {delCahrges} + {charges}
                                                                    = {total}
                                                                </b>
                                                                :
                                                                <b>
                                                                    {orderData.Product.Charge_Price} + {orderData.DC} + {orderData.Charges}
                                                                    = {orderData.TotalBill}
                                                                </b>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        Discount
                                                    </th>
                                                    <td>
                                                        <b>0.0</b>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <th>
                                                        Total Bill
                                                    </th>
                                                    <td>
                                                        <b>
                                                            {orderData.TotalBill}
                                                        </b>
                                                    </td>
                                                </tr>

                                            </thead>
                                        </table>
                                    </div>
                                    :
                                    ""
                        }
                        <div className='row'>
                         
                            {
                                orderData.Status == 'Confirmed' ?
                                    getUser.GenerateBill ?
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                            style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                            data-bs-target="#staticBackdro" onClick={() => {
                                                showStatus(orderData._id)
                                            }}>
                                            {updateTo}
                                        </button>
                                        :
                                        <button type="button" class="btn btn-primary"
                                            style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}>
                                            Can't update Status
                                        </button>
                                    :
                                    orderData.Status == "Completed" ?
                                        getUser.CollectBill ?
                                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                                style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                                data-bs-target="#staticBackdro" onClick={() => {
                                                    showStatus(orderData._id)
                                                }}>
                                                {updateTo}
                                            </button>
                                            :
                                            <button type="button" class="btn btn-primary"
                                                style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}>
                                                Can't update Status
                                            </button>
                                        :
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                            style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                            data-bs-target="#staticBackdro" onClick={() => {
                                                showStatus(orderData._id)
                                            }}>
                                            {updateTo}
                                        </button>

                            }

                            <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Order Status</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">

                                            {
                                                loading ?
                                                    <span>
                                                        Loading...
                                                    </span>
                                                    :

                                                    <span>
                                                        <b>Order has been updated </b>
                                                        <br />

                                                        <br />
                                                        <a href='/stitching/confirmOrder'>
                                                            Go to confirm {orderData.OrderNumber}
                                                        </a>
                                                    </span>

                                            }
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default StitchingOrderView