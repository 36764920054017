import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../Configuration/Api';

function AllStitchingProducts() {
    const [empty, setEmpty] = useState(false)
    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const getOrders = async () => {

        fetch(`${config.baseurl}/stitching/sub/all/products/notrandom`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {
                setProducts(responseData.Response)
                // console.log("come",responseData.Response);

                if (responseData.Response.length == 0) {
                    console.log("enter");
                    setEmpty(true)
                }
                // console.log(responseData.Response.lenght);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };
    useEffect(() => {
        getOrders();
    }, []);
    // getOrders();


    return (
        <div>

            <nav aria-label="breadcrumb" className='container bg-light p-1 mt-1'>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/products">Products</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Stitching Design</li>
                </ol>
            </nav>
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>


                All Design's
            </div>

            {
                empty ?
                    <div style={{
                        height: "60px",
                        width: "100%",
                        color: "black",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        textAlign: "center"
                    }}>
                        <span>Empty!<br></br></span>
                        No new order is available
                    </div>
                    :
                    <table className="container table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">Design Code</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Cost</th>
                                <th scope="col">Category</th>
                                <th scope="col">DesignType</th>
                                <th scope="col">Product</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                products.map((item, index) => {
                                    return (
                                        <tr onClick={() => {

                                            navigate("/stitching/products/view", {
                                                state: {
                                                    Product: item,
                                                }
                                            })
                                        }}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.Name}</td>
                                            <td>{item.Design_SKU}</td>
                                            <td>{item.main_category.general_category.Name}</td>
                                            <td>{item.Charge_Price}<strong> Rs</strong></td>
                                            <td>{item.main_category.Name}</td>
                                            <td>{item.DesignType}</td>
                                            <td><img src={item.Images[0]} width={120} height={60} /></td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>

            }

        </div>
    )
}

export default AllStitchingProducts