import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { config } from '../../Configuration/Api'
import { OrderStatus } from '../../Configuration/ApiIntegration'

function ReadyMAdeOrderView() {

    const [loading, setLoading] = useState(false)
    const [presentStatus, setPresentStatus] = useState()
    const [orderAppLocation, setOrderAppLocation] = useState()
    const location = useLocation()
    const orderData = location.state.Order
    const updateTo = location.state.updateto
    const [app, setApp] = useState(false)
    useEffect(() => {
        if (orderData.From == "App") {
            setApp(true)
            console.log(orderData.Order);
            // setOrderAppLocation(JSON.parse(orderData.Order))
            return
        }
    }, [])

    const showStatus = async (id) => {
        setLoading(true)
        console.log(id);

        await fetch(`${config.baseurl}/ready_made/order/${updateTo}/${id}`,
        ).then(
            (response) => response.json()
        ).catch((err) => {
            // alert("something went wrong. Please try again")
            console.log(err);
        }).then(responseData => {
            console.log(responseData)
            setLoading(false)
            // console.log(responseData.order.Status);
            // setPresentStatus(responseData.order.Status)


        })
    }



    console.log(orderData);
    return (
        <div >
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                ReadyMade Order Details
            </div>
            <div className='container mt-1' style={{
                backgroundColor: "black",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                // textAlign: "center"


            }}>
                Product Details
            </div>
            {
                orderData.Order.product.map((item) => {
                    return (
                        <div className='row align-items-center container mt-2 ' style={{
                            margin: "auto"
                        }}>

                            <div className='col'>

                                <table className=" table ">
                                    <thead>
                                        <tr>
                                            <th >Product Name</th>
                                            <th >Product No</th>
                                            <th >Cost</th>
                                            <th >Product type</th>
                                            <th>Available Stock</th>
                                            <th>Product</th>

                                        </tr>
                                        <tr>
                                            <td>{item.Name}</td>
                                            <td>{item.SKU}</td>
                                            <td>{item.Charge_Price}<strong> Rs</strong></td>
                                            <td>{item.main_category.type}</td>
                                            <td>{item.SoldOut}</td>
                                            <img src={item.Images[0]} width={120} height={60} />

                                        </tr>

                                        <tr>
                                            <td> <img src={item.Images[0]} className="border" width={"100%"} height={200} /></td>
                                            <td> <img src={item.Images[1]} className="border" width={"100%"} height={200} /></td>
                                            <td><img src={item.Images[2]} className="border" width={"100%"} height={200} /></td>
                                            <td> <img src={item.Images[3]} className="border" width={"100%"} height={200} /></td>
                                            <td> <img src={item.Images[4]} className="border" width={"100%"} height={200} /></td>
                                        </tr>
                                        {/* <tr>
                                            <th >Cost</th>
                                            <th >Product type</th>
                                        </tr>
                                        <tr>
                                            <td>{item.Charge_Price}<strong> Rs</strong></td>
                                            <td>{item.main_category.type}</td>
                                        </tr> */}
                                    </thead>
                                </table>

                            </div>

                        </div>
                    )
                })
            }



            <div className='container mt-1' style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                // textAlign: "center"


            }}>
                User Details
            </div>

            {
                app ?
                    <div>
                        <table className=" table container table-striped ">
                            <thead>
                                <tr>
                                    <th >Customer Name</th>
                                    <th >Customer Gender</th>
                                </tr>
                                <tr>
                                    <td>{orderData.User.Name}</td>
                                    <td>{orderData.User.Gender}</td>
                                </tr>
                                <tr>
                                    <th >Email</th>
                                    <th >Phone</th>
                                </tr>
                                <tr>
                                    <td>{orderData.User.Email}</td>
                                    <td>{orderData.User.Phone}</td>
                                </tr>
                            </thead>
                        </table>

                        <div className='container mt-1' style={{
                            backgroundColor: "grey",
                            height: "60px",
                            width: "100%",
                            color: "white",
                            fontSize: 34,
                            fontWeight: "bold",
                            fontFamily: "initial",
                            // textAlign: "center"


                        }}>
                            Order description
                        </div>



                        <table className=" table container table-striped ">
                            <thead>
                                <tr>
                                    <th >Order Customer Name</th>
                                    <th >Order Customer Phone</th>
                                    <th >Address</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.name}</td>
                                    <td>{orderData.Order.phone}</td>
                                    <td>{orderData.Order.address}</td>
                                </tr>
                                <tr>

                                    <th >Size</th>
                                    <th >Order Number</th>
                                    <th>Order Status</th>

                                </tr>
                                <tr>
                                    <td>{orderData.Order.size}</td>
                                    <td>{orderData.OrderNumber}</td>
                                    <td>{orderData.Status}</td>
                                </tr>


                            </thead>
                        </table>

                        <div className='row'>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                data-bs-target="#staticBackdro" onClick={() => {
                                    showStatus(orderData._id)
                                }}>
                                {updateTo}
                            </button>

                            <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Order Status</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">

                                            {
                                                loading ?
                                                    <span>
                                                        Loading...
                                                    </span>
                                                    :

                                                    <span>
                                                        <b>
                                                            Order has been updated From
                                                            <strong> {orderData.Status}</strong>
                                                            to {updateTo}
                                                        </b>
                                                        <br />
                                                        <b>Order Number <strong>{orderData.OrderNumber}</strong></b>
                                                        <br />
                                                        {/* <a href='/stitching/confirmOrder'>
                                                            Go to confirm {orderData.OrderNumber}
                                                        </a> */}
                                                    </span>

                                            }
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :

                    <div>

                        <table className=" table container table-striped ">
                            <thead>
                                <tr>
                                    <th >Customer Name</th>
                                    <th >Customer Gender</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.name}</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <th >Email</th>
                                    <th >Phone</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.email}</td>
                                    <td>{orderData.Order.phone}</td>
                                </tr>
                            </thead>
                        </table>

                        <div className='container mt-1' style={{
                            backgroundColor: "grey",
                            height: "60px",
                            width: "100%",
                            color: "white",
                            fontSize: 34,
                            fontWeight: "bold",
                            fontFamily: "initial",
                            // textAlign: "center"


                        }}>
                            Order description
                        </div>

                        <table className=" table container table-striped ">
                            <thead>
                                <tr>
                                    <th >Order Size</th>
                                    <th >Order Number</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.size}</td>
                                    <td>{orderData.OrderNumber}</td>
                                </tr>
                                <tr>
                                    <th >Address</th>
                                    <th >Requirement</th>
                                </tr>
                                <tr>
                                    <td>{orderData.Order.address}</td>
                                    <td>{orderData.Order.requirement}</td>
                                </tr>


                            </thead>
                        </table>

                        <div className='row'>
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                data-bs-target="#staticBackdro" onClick={() => {
                                    showStatus(orderData._id)
                                }}>
                                {updateTo}
                            </button>

                            <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">Order Status</h5>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">

                                            {
                                                loading ?
                                                    <span>
                                                        Loading...
                                                    </span>
                                                    :

                                                    <span>
                                                        <b>Order has been updated </b>
                                                        <br />

                                                        <br />
                                                        <a href='/stitching/confirmOrder'>
                                                            Go to confirm {orderData.OrderNumber}
                                                        </a>
                                                    </span>

                                            }
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}

export default ReadyMAdeOrderView