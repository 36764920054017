import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { config } from '../../Configuration/Api'
import { OrderStatus } from '../../Configuration/ApiIntegration'
import ReactToPrint from 'react-to-print'
function SpecialrequestView() {

    const [loading, setLoading] = useState(false)
    const location = useLocation()
    const orderData = location.state.Order
    const updateTo = location.state.updateto
    const [editReq, setEditReq] = useState()
    const [editLoading, setEditLoading] = useState(false)

    // this is for charges the request [1500, 2000 ,3000]
    const [charges, setCharges] = useState()
    //this is for add the notes for admin side
    const [note, setNote] = useState()
    //this is for select the type of request [Simple , Fancy , Embrioded]
    const [selcPrice, setSelcPrice] = useState('simple')
    //this is for select the distance [ Shop ,10km ,15km , 20km]
    const [selcDel, setSelcdel] = useState('shop')
    //this is for delivery charges  [ 0,150,200,250]
    const [delCahrges, setDelCharges] = useState()

    const [billEdit, setBillEdit] = useState(false)
    const [billNotes, setBillNotes] = useState()
    const parse = localStorage.getItem("data")
    const getUser = JSON.parse(parse)
    const componentRef = useRef()
    const total = delCahrges + parseInt(charges)

    const showStatus = async (id) => {
        setLoading(true)
        // console.log(id, updateTo);
        // ${config.baseurl}
        await fetch(`${config.baseurl}/stitching/order/specialRequest/${updateTo}/${id}`,
        ).then(
            (response) => response.json()
        ).catch((err) => {
            // alert("something went wrong. Please try again")
            console.log(err);
        }).then(responseData => {
            console.log(responseData)
            setLoading(false)
        })
    }

    const updateNotes = async (source) => {
        setEditLoading(true)
        await fetch(`${config.baseurl}/stitching/order/specialRequest/addNote/${orderData._id}`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    notes: note
                })
            }).then(
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again")
                console.log(err);
            }).then(responseData => {

                if (responseData.success == true) {

                    setEditLoading(false)
                    return
                }
                else {
                    setEditLoading(false)
                    alert("Error! Special Request notes is not updated")
                }

            })
    }

    const orderCancel = async (source) => {
        setEditLoading(true)


        await fetch(`${config.baseurl}/stitching/order/specialRequest/cancel/${orderData._id}`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    note: note,
                })
            }).then(
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again")
                console.log(err);
            }).then(responseData => {
                // console.log(responseData);
                if (responseData.success == true) {

                    setEditLoading(false)
                    return
                }
                else {
                    setEditLoading(false)
                    alert("Error! special request can not be cancelled")
                }

            })
    }

    const updateBill = async () => {
        setEditLoading(true)
        console.log(orderData._id);
        // ${config.baseurl}
        await fetch(`${config.baseurl}/stitching/order/specialRequest/update/${orderData._id}`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    // requirement: source == "App" ? orderAppLocation : orderData.Order
                    notes: billNotes,
                    dc: delCahrges,
                    charges: charges
                })
            }).then(
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again")
                console.log(err);
            }).then(responseData => {
                // console.log(responseData);
                if (responseData.success == true) {

                    setEditLoading(false)
                    return
                }
                else {
                    setEditLoading(false)
                    alert("Error! Requirement is not updated")
                }

            })
    }
    const date = new Date(orderData.createdAt)


    return (
        <div >
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                SpecialRequest Details
            </div>

            <div className='container mt-1' style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                // textAlign: "center"


            }}>
                User Details
            </div>


            <div>
                <table className=" table container table-striped ">
                    <thead>
                        <tr>
                            <th >Customer Name</th>
                            <th >Customer Gender</th>
                            <th>From</th>
                        </tr>
                        <tr>
                            <td>{orderData.Order.Name || orderData.User.Name}</td>
                            <td>{orderData.Order.Gender || orderData.User.Gender}</td>
                            <td> {orderData.From} </td>
                        </tr>
                        <tr>
                            <th >Email</th>
                            <th >Phone</th>
                            <th>Status</th>
                        </tr>
                        <tr>
                            <td>{orderData.Order.Email || orderData.User.Email}</td>
                            <td>{orderData.Order.Phone || orderData.User.Phone}</td>
                            <td>{orderData.Status}</td>
                        </tr>
                        <tr>
                            <th>
                                <b>
                                    FOR CANCEL ORDER
                                </b>
                            </th>
                        </tr>
                        <tr>
                            <td>
                                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                    style={{ backgroundColor: "red", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                    data-bs-target="#cancel" >
                                    Cancel
                                </button>

                                <div class="modal fade" id="cancel" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="staticBackdropLabel">Please write Reason for cancel order</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">

                                                {/* <input style={{
                                                            width: "100%"
                                                        }} /> */}
                                                <textarea style={{
                                                    width: "100%"
                                                }} value={note}
                                                    multiple={true}
                                                    onChange={(val) => setNote(val.target.value)}>

                                                </textarea>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary"

                                                    onClick={() => {

                                                        orderCancel()
                                                    }}>
                                                    {
                                                        editLoading ? "Loading ..." : "Click Cancel"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </thead>
                </table>

                <div className='container mt-1' style={{
                    backgroundColor: "grey",
                    height: "60px",
                    width: "100%",
                    color: "white",
                    fontSize: 34,
                    fontWeight: "bold",
                    fontFamily: "initial",
                    // textAlign: "center"


                }}>
                    Request Details
                </div>
                <table className=" table container table-striped ">
                    <thead>
                        <tr>
                            <th >Request Gender</th>
                            <th >Request Number</th>
                            <th>Date</th>
                        </tr>
                        <tr>
                            <td>{orderData.Order.Gender}  </td>
                            <td>{orderData.OrderNumber}</td>
                            <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                        </tr>
                        <tr>
                            <th>Design Request</th>
                            <th >Requirement</th>
                        </tr>
                        <tr>
                            <td>
                                {orderData.Order.Design}
                            </td>
                            <td>
                                {orderData.Order.Requirement}
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Notes
                            </th>
                        </tr>
                        <tr>
                            <td >{orderData.Note}{"\n"}
                                <br />
                                {/* Start updating request notes  */}
                                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                    style={{ backgroundColor: "green", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                    data-bs-target="#staticBackdro1" >
                                    Add Note
                                </button>

                                <div class="modal fade" id="staticBackdro1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="staticBackdropLabel">Requirement Update</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body">

                                                <textarea style={{
                                                    width: "100%"
                                                }} value={note}
                                                    multiple={true}
                                                    onChange={(val) => setNote(val.target.value)}>

                                                </textarea>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary"

                                                    onClick={() => {
                                                        updateNotes()
                                                    }}>
                                                    {
                                                        editLoading ? "Loading ..." : "Save"
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div></td>
                        </tr>
                    </thead>
                </table>

                {/* This is for special request bill */}

                {
                    orderData.Status == "Ordered" ?
                        <div>
                            <div className='container mt-3 mb-3' style={{
                                backgroundColor: "green",
                                height: "60px",
                                width: "100%",
                                color: "white",
                                fontSize: 34,
                                fontWeight: "bold",
                                fontFamily: "initial",
                                // textAlign: "center"


                            }}>
                                Payment

                                {
                                    billEdit ?
                                        <div style={{ position: "relative" }}>


                                            <button style={{ position: "absolute", right: "10%", top: -50, backgroundColor: "blue", color: "white" }}
                                                onClick={() => {
                                                    updateBill()
                                                }}>
                                                {editLoading ? 'Saving ...' : "Saved"}
                                            </button>
                                            <button style={{ position: "absolute", right: "30%", top: -50, backgroundColor: "tomato", color: "white" }}
                                                onClick={() => {
                                                    setBillEdit(false)
                                                }}>
                                                Cancel
                                            </button>
                                        </div>
                                        :
                                        //check if bill is edit till now or not
                                        //if yes then only allow to generate the slip

                                        getUser.GenerateBill ?
                                            orderData.Edit ?
                                                <ReactToPrint
                                                    trigger={() =>
                                                        <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}>
                                                            Generate bill
                                                        </button>
                                                    }
                                                    content={() => componentRef.current}
                                                />
                                                :
                                                <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                                    onClick={() => {
                                                        setBillEdit(true)
                                                    }}>
                                                    Edit
                                                </button>
                                            :
                                            <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}
                                            >
                                                UnAuthorize
                                            </button>



                                }
                            </div>
                            {/* this is for receipt  */}
                            {
                                orderData.Edit ?

                                    <div ref={componentRef} style={{}} >
                                        {/* Receipt Header */}
                                        <p className='text-center m-0'>
                                            <b style={{ fontSize: 20 }}>FashionWise</b>
                                            <br />
                                            Fit ha tou , hit hai
                                        </p>
                                        <p className='text-center m-0'>
                                            <b>Address:</b> 70 D Khyber Block Main wahdat Road
                                            <br />
                                            Allama Iqbal Town , Lahore
                                            <br />
                                            0326 4321272
                                        </p>
                                        {/* Customer details */}
                                        <table className='table table-borderless table-sm m-0'>
                                            <tbody>
                                                <tr>
                                                    <td>Customer</td>
                                                    <td><b style={{ textTransform: "capitalize" }}>{orderData.Order.Name || orderData.User.Name}</b></td>
                                                </tr>

                                                <tr>
                                                    <td>Order</td>
                                                    <td>{orderData.OrderNumber}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className='table table-bordered table-sm  ' >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Service
                                                    </th>
                                                    <th>
                                                        Code
                                                    </th>
                                                    <th>
                                                        Price
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Special Request</td>
                                                    <td>{orderData.OrderNumber}</td>
                                                    <td>{orderData.Charges}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        DC
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        {orderData.DC}
                                                    </td>
                                                </tr>



                                            </tbody>
                                        </table>

                                        <table className='table table-borderless table-sm text-center'>
                                            <tfoot>
                                                <tr>
                                                    <td>
                                                        Sub Total
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        {orderData.TotalBill}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Discount
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        0.0
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b style={{ fontSize: 22 }}>
                                                            Total
                                                        </b>
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        <b style={{ fontSize: 20 }}>
                                                            {orderData.TotalBill}
                                                        </b>

                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <div className='text-center'>
                                            <b >
                                                ************
                                            </b>
                                        </div>

                                        <div>
                                            <p style={{ textTransform: "capitalize" }}>
                                                <b>Charges Detail: </b>
                                                {orderData.BillNote}
                                            </p>
                                            <p className='text-center'>
                                                <b style={{ fontSize: 20 }}>Thank You!</b>

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    ''
                            }
                            <table className=" table container table-striped  table-bordered border-primary ">
                                <thead>

                                    <tr>

                                        <th >Delivery Charges
                                            {"\n\n"}
                                            <b style={{ backgroundColor: "green", color: "wheat", padding: "1%" }}>
                                                {orderData.DC} Rs
                                            </b>
                                        </th>
                                        <th >Design  Charges
                                            <b style={{ backgroundColor: "green", color: "wheat", padding: "1%" }}>
                                                {orderData.Charges} Rs
                                            </b>
                                        </th>

                                    </tr>
                                    <tr>

                                        <td>
                                            {
                                                billEdit ?
                                                    // <input value={charges} onChange={(val) => setCharges(val.target.value)} />
                                                    <ul>
                                                        {/* shop*/}
                                                        {
                                                            selcDel == "shop" ?
                                                                <li onClick={() => { setDelCharges(0); setSelcdel('shop') }} style={{ cursor: "pointer" }} >
                                                                    Shop 0
                                                                    <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                        Selected {selcDel}
                                                                    </p>
                                                                </li>
                                                                :
                                                                <li onClick={() => { setDelCharges(0); setSelcdel('shop') }} style={{ cursor: "pointer" }} >Shop 0</li>
                                                        }

                                                        {
                                                            selcDel == "10km" ?
                                                                <li onClick={() => { setDelCharges(150); setSelcdel('10km') }} style={{ cursor: "pointer" }} >
                                                                    10Km  <b style={{ color: "green" }}>150Rs</b>
                                                                    <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                        Less then {selcDel}
                                                                    </p>
                                                                </li>
                                                                :
                                                                <li onClick={() => { setDelCharges(150); setSelcdel('10km') }} style={{ cursor: "pointer" }} >10KM <b style={{ color: "green" }}>150Rs</b></li>
                                                        }

                                                        {
                                                            selcDel == "15km" ?
                                                                <li onClick={() => { setDelCharges(200); setSelcdel('15km') }} style={{ cursor: "pointer" }} >
                                                                    15Km  <b style={{ color: "green" }}>200Rs</b>
                                                                    <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                        Less then {selcDel}
                                                                    </p>
                                                                </li>
                                                                :
                                                                <li onClick={() => { setDelCharges(200); setSelcdel('15km') }} style={{ cursor: "pointer" }} >15KM <b style={{ color: "green" }}>200Rs</b></li>
                                                        }

                                                        {
                                                            selcDel == "20km" ?
                                                                <li onClick={() => { setDelCharges(250); setSelcdel('20km') }} style={{ cursor: "pointer" }} >
                                                                    20Km  <b style={{ color: "green" }}>250Rs</b>
                                                                    <p style={{ backgroundColor: "black", textAlign: "center", color: 'white', width: '35%', fontWeight: "bold" }}>
                                                                        Less then {selcDel}
                                                                    </p>
                                                                </li>
                                                                :
                                                                <li onClick={() => { setDelCharges(250); setSelcdel('20km') }} style={{ cursor: "pointer" }} >20KM <b style={{ color: "green" }}>250Rs</b></li>
                                                        }




                                                    </ul>
                                                    :
                                                    <p>
                                                        {orderData.DC} Rs
                                                    </p>
                                            }</td>

                                        <td>
                                            {
                                                billEdit ?
                                                    <input value={charges} type='number' onChange={(val) => setCharges(val.target.value)} />
                                                    :
                                                    <b>
                                                        {
                                                            orderData.Charges
                                                        }
                                                    </b>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Extra Charges Comment
                                        </th>
                                        <td>
                                            {
                                                billEdit ?
                                                    <div>
                                                        <b>
                                                            {orderData.BillNote}
                                                        </b>
                                                        <textarea value={billNotes} onChange={(val) => setBillNotes(val.target.value)}
                                                            placeholder={orderData.BillNote} style={{ width: "100%" }} />
                                                    </div>
                                                    :
                                                    <b>
                                                        {
                                                            orderData.BillNote
                                                        }
                                                    </b>
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            Sub total
                                        </th>
                                        <td>
                                            {
                                                billEdit ?
                                                    <b>
                                                        {charges}+ {delCahrges}
                                                        = {total}
                                                    </b>
                                                    :
                                                    <b>
                                                        {orderData.DC} + {orderData.Charges}
                                                        = {orderData.TotalBill}
                                                    </b>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Discount
                                        </th>
                                        <td>
                                            <b>0.0</b>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            Total Bill
                                        </th>
                                        <td>
                                            <b>
                                                {orderData.TotalBill}
                                            </b>
                                        </td>
                                    </tr>

                                </thead>
                            </table>
                        </div>
                        :

                        // only show bill in all state ordered / confirmed / completed
                        <div>
                            <div className='container mt-3 mb-3' style={{
                                backgroundColor: "green",
                                height: "60px",
                                width: "100%",
                                color: "white",
                                fontSize: 34,
                                fontWeight: "bold",
                                fontFamily: "initial",
                                // textAlign: "center"
                            }}>
                                Payment
                                <ReactToPrint
                                    trigger={() =>
                                        <button style={{ position: "absolute", right: "10%", backgroundColor: "black", color: "white" }}>
                                            Generate bill
                                        </button>
                                    }
                                    content={() => componentRef.current}
                                />
                            </div>

                            {
                                orderData.Edit ?

                                    <div ref={componentRef} style={{}} >
                                        {/* Receipt Header */}
                                        <p className='text-center m-0'>
                                            <b style={{ fontSize: 20 }}>FashionWise</b>
                                            <br />
                                            Fit ha tou , hit hai
                                        </p>
                                        <p className='text-center m-0'>
                                            <b>Address:</b> 70 D Khyber Block Main wahdat Road
                                            <br />
                                            Allama Iqbal Town , Lahore
                                            <br />
                                            0326 4321272
                                        </p>
                                        {/* Customer details */}
                                        <table className='table table-borderless table-sm m-0'>
                                            <tbody>
                                                <tr>
                                                    <td>Customer</td>
                                                    <td><b style={{ textTransform: "capitalize" }}>{orderData.Order.Name || orderData.User.Name}</b></td>
                                                </tr>

                                                <tr>
                                                    <td>Order</td>
                                                    <td>{orderData.OrderNumber}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className='table table-bordered table-sm  ' >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Service
                                                    </th>
                                                    <th>
                                                        Code
                                                    </th>
                                                    <th>
                                                        Price
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Special Request</td>
                                                    <td>{orderData.OrderNumber}</td>
                                                    <td>{orderData.Charges}</td>
                                                </tr>

                                                <tr>
                                                    <td>
                                                        DC
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        {orderData.DC}
                                                    </td>
                                                </tr>



                                            </tbody>
                                        </table>

                                        <table className='table table-borderless table-sm text-center'>
                                            <tfoot>
                                                <tr>
                                                    <td>
                                                        Sub Total
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        {orderData.TotalBill}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Discount
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        0.0
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <b style={{ fontSize: 22 }}>
                                                            Total
                                                        </b>
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        <b style={{ fontSize: 20 }}>
                                                            {orderData.TotalBill}
                                                        </b>

                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                        <div className='text-center'>
                                            <b >
                                                ************
                                            </b>
                                        </div>

                                        <div>
                                            <p style={{ textTransform: "capitalize" }}>
                                                <b>Charges Detail: </b>
                                                {orderData.BillNote}
                                            </p>
                                            <p className='text-center'>
                                                <b style={{ fontSize: 20 }}>Thank You!</b>

                                            </p>
                                        </div>
                                    </div>
                                    :
                                    ''
                            }

                        </div>

                }
                {/* Ending here payment of speical request */}


                {/* start of updating status */}

                <div className='row mb-5 mt-5'>
                    {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                        style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                        data-bs-target="#staticBackdro" onClick={() => {
                            showStatus(orderData._id)
                        }}>
                        {updateTo}
                    </button> */}

                    {
                        orderData.Status == 'Ordered' ?
                            getUser.GenerateBill ?
                                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                    style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                    data-bs-target="#staticBackdro" onClick={() => {
                                        showStatus(orderData._id)
                                    }}>
                                    {updateTo}
                                </button>
                                :
                                <button type="button" class="btn btn-primary"
                                    style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}>
                                    Can't update Status
                                </button>
                            :
                            orderData.Status == "Completed" ?
                                getUser.CollectBill ?
                                    <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                        style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                        data-bs-target="#staticBackdro" onClick={() => {
                                            showStatus(orderData._id)
                                        }}>
                                        {updateTo}
                                    </button>
                                    :
                                    <button type="button" class="btn btn-primary"
                                        style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}>
                                        Can't update Status
                                    </button>
                                :
                                <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                    style={{ width: "40%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                                    data-bs-target="#staticBackdro" onClick={() => {
                                        showStatus(orderData._id)
                                    }}>
                                    {updateTo}
                                </button>

                    }

                    <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="staticBackdropLabel">Order Status</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">

                                    {
                                        loading ?
                                            <span>
                                                Loading...
                                            </span>
                                            :
                                            <span>
                                                <b>
                                                    Order has been updated From
                                                    <strong> {orderData.Status}</strong>
                                                    --  to  {updateTo}
                                                </b>
                                                <br />
                                                <b>Order Number <strong>{orderData.OrderNumber}</strong></b>
                                                <br />
                                            </span>

                                    }
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




        </div>
    )
}

export default SpecialrequestView