import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "../Assests/logo.png"
import webLogo from "../Assests/Images/webLogo.svg"
import LoginPage from '../Screens/LoginPage';
function Header() {
    const navigate = useNavigate()
    const heading = {
        fontWeight: 700,
        display: 'block',
        textTransform: "uppercase",
        letterSpacing: '.2em',
        fontSize: '.85em',
        color: '#000',
        paddingTop: "10px"
    };
    const message = {
        fontWeight: 700,
        display: 'block',
        textTransform: "uppercase",
        letterSpacing: '.2em',
        fontSize: '.85em',
        color: '#000',
        paddingTop: "5px"
    };

    return (
        <div>




            <div className='contianer' style={{}}>

                <nav class="navbar navbar-expand-lg navbar-light  py-4" style={{}}>
                    <div class="container-fluid" style={{}}>
                        <button class="navbar-toggler" style={{ color: "#ddd" }} type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        {/* <a class="navbar-brand mx-auto" href="/">FashionWise

                        </a> */}


                        <a class="navbar-brand mx-auto" onClick={async () => {
                            navigate("/dashboard")

                        }}>
                            {/* https://cdn.shopify.com/s/files/1/1592/0041/files/NEW_LOGO_-_Black.svg?v=1675150363 */}
                            <img src={webLogo}
                                alt="" width="170" height="60" className="d-inline-block align-text-top" />
                        </a>
                        <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0 mx-auto">
                                <li class="nav-item" style={{
                                    paddingLeft: "10px",
                                    paddingRight: '10px'
                                }} >
                                    <button class="nav-link active" aria-current="page" onClick={async () => {
                                        navigate("/dashboard")

                                    }}>
                                        Home
                                    </button>
                                </li>

                                <li class="nav-item" style={{
                                    paddingLeft: "10px",
                                    paddingRight: '10px'
                                }} >
                                    <button class="nav-link active" aria-current="page" onClick={async () => {
                                        navigate("/")
                                        localStorage.removeItem("data")
                                        // navigate("/")
                                    }}>
                                        Logout
                                    </button>
                                </li>

                                {/* <li class="nav-item" style={{
                                    paddingLeft: "10px",
                                    paddingRight: '10px'
                                }}>
                                    <a class="nav-link" href="#">Trending</a>
                                </li> */}

                                {/* <li class="nav-item" style={{
                                    paddingLeft: "10px",
                                    paddingRight: '10px'
                                }}>
                                    <a class="nav-link" href="/readyMade">
                                        ReadyMade 
                                    </a>
                                </li> */}

                                {/* <li class="nav-item dropdown" style={{
                                    paddingLeft: "10px",
                                    paddingRight: '10px'
                                }}>
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Women
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><a class="dropdown-item" href="/stitching-design/Women">Women's Design</a></li>
                                        <li><hr class="dropdown-divider" /></li>
                                        <li><a class="dropdown-item" href="/ready-to-wear/women">Women's Ready to Wear</a></li>

                                    </ul>
                                </li> */}

                                {/* <li class="nav-item" style={{
                                    paddingLeft: "10px",
                                    paddingRight: '10px'
                                }}>
                                    <a class="nav-link" href="/stitching">
                                        Stitching 
                                    </a>
                                </li> */}


                                {/* 
                                <li class="nav-item dropdown" style={{
                                    paddingLeft: "30px",
                                    paddingRight: '30px'
                                }}>
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Men
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><a class="dropdown-item" href="/stitching-design/Men">Men's Design</a></li>
                                        <li><hr class="dropdown-divider" /></li>
                                        <li><a class="dropdown-item" href="/ready-to-wear/man">Men's Ready to Wear</a></li>

                                    </ul>
                                </li> */}
                                {/* 
                                <li class="nav-item dropdown" style={{
                                    paddingLeft: "10px",
                                    paddingRight: '10px'
                                }}>
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        Kids
                                    </a>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><a class="dropdown-item" href="#">Kids's Design</a></li>
                                        <li><hr class="dropdown-divider" /></li>
                                        <li><a class="dropdown-item" href="/ready-to-wear/kids">Kids's Ready to Wear</a></li>

                                    </ul>
                                </li> */}

                                {/* 
                                <li class="nav-item" style={{
                                    paddingLeft: "10px",
                                    paddingRight: '10px'
                                }}>
                                    <a class="nav-link" href="/stitching/products">Stitching Products</a>
                                </li> */}



                            </ul>

                            {/* <form class="d-flex">
                                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                                <button class="btn btn-outline-success" type="submit">Search</button>
                            </form> */}
                        </div>

                        {/* <div>
                            <a class="btn" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample">
                                <i class="bi bi-search-heart-fill" style={{ color: "black", fontSize: 20 }}></i>
                            </a>

                        </div> */}

                    </div>
                </nav >
            </div >

            {/* <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title" id="offcanvasExampleLabel">FashioWise</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div class="offcanvas-body">
                    <div>
                        Some text as placeholder. In real life you can have the elements you have chosen. Like, text, images, lists, etc.
                    </div>
                    <div class="dropdown mt-3">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown">
                            Dropdown button
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><a class="dropdown-item" href="#">Action</a></li>
                            <li><a class="dropdown-item" href="#">Another action</a></li>
                            <li><a class="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </div >
    )
}

export default Header