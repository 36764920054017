import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Crousel from './Components/Crousel';
import Trending from './Components/Trending';
import Design from './Components/Design';
import Category from './Components/Category';
import NewArrivals from './Components/NewArrivals';
import { BrowserRouter, HashRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Screens/Home';
import AllViewScreen from './Screens/AllViewScreen';
import SpecificProductScreen from './Screens/SpecificProductScreen';
import StitchingOrderScreen from './Screens/StitchingOrderScreen';
import ViewReadytoWear from './Screens/ViewReadytoWears';
import ViewDesignScreen from './Screens/ViewDesignScreen';
import SpecificReadytoWearScreen from './Screens/SpecificReadytoWear';
import CollectionScreen from './Screens/CollectionScreen';
import Test from './Components/Test';
import FooterTest from './Components/FooterTest';
import Exchange from './Pages/Exchange';
import Contact from './Pages/Contact';
import AboutUs from './Pages/AboutUs';
import OrderTracking from './Screens/OrderTracking';
import StitchingCollection from './Screens/StitchingCollection';
import OrdersRecord from './Screens/OrdersRecord';
import Stitching from './Screens/Stitching';
import StitchingNewOrder from './Screens/Stitching/StitchingNewOrder';
import StitchingOrderView from './Screens/Stitching/StitchingOrderView';
import StitchingConfirm from './Screens/Stitching/StitchingConfirm';
import StitchingComplete from './Screens/Stitching/StitchingComplete';
import StitchingDeliever from './Screens/Stitching/StitchingDeliever';
import ReadyMade from './Screens/ReadyMade';
import ReadyMadeNewOrders from './Screens/ReadyMade/ReadyMadeNewOrders';
import ReadyMAdeOrderView from './Screens/ReadyMade/ReadyMadeOrderView';
import ReadyMadeConfirmOrders from './Screens/ReadyMade/ReadyMadeConfirmOrder';
import ReadyMadeCompleteOrders from './Screens/ReadyMade/ReadyMadeCompleteOrders';
import ReadyMadeDelieverOrders from './Screens/ReadyMade/ReadyMadeDelieverOrders';
import AllStitchingProducts from './Screens/Products/AllStitchingProducts';
import ViewStitchingProduct from './Screens/Products/ViewStitchingProduct';
import AllReadyMadeProducts from './Screens/Products/ReadyProducts';
import ViewReadyMadeProducts from './Screens/Products/ViewReadyMadeProducts';
import Sale from './Screens/Sale';
import Products from './Screens/Products/Products';
import LoginPage from './Screens/LoginPage';
import SpecialRequest from './Screens/Stitching/SpecialRequest';
import SpecialrequestView from './Screens/Stitching/SpecialrequestView';
import AddSpecialRequest from './Screens/Stitching/AddSpecialRequest';
import DeliveredAll from './Screens/DeliveredAll';
import SpecialRequestDeliveredAll from './Screens/Stitching/SpecialRequestDeliveredAll';
import StitchingDeliverView from './Screens/Stitching/StitchingDeliverView';
import SpecialRequestDeliverView from './Screens/Stitching/SpecialRequestDeliverView';
import CancelledAll from './Screens/CancelledAll';
import SpecialRequestCancelledAll from './Screens/SpecialRequestCancelList';
import StitchingCancelList from './Screens/Stitching/StitchingCancelList';
import Riders from './Screens/Riders';

function App() {
  const pic1 = 'https://cdn.shopify.com/s/files/1/1592/0041/files/DTD-SLIDER-WEB.jpg?v=1674730552&width=1100'
  const pic2 = 'https://cdn.shopify.com/s/files/1/1592/0041/files/DTD-SLIDER-MOBILE.jpg?v=1674730552&width=1100'
  // const navigate = useNavigate()
  const getData = localStorage.getItem("data")
  const getUser = JSON.parse(getData)
  return (
    <div className="App" style={{

    }}>





      <BrowserRouter >

        {
          getUser ? <Header /> : ''
        }
        <Routes >
          <Route path='/' element={<LoginPage />} />

          <Route path='/dashboard' element={<Home />} />

          <Route path='/stitching' element={<Stitching />} />
          <Route path='/stitching/newOrder' element={<StitchingNewOrder />} />
          <Route path='/stitching/newOrder/view' element={<StitchingOrderView />} />
          <Route path='/stitching/confirmOrder' element={<StitchingConfirm />} />
          <Route path='/stitching/completeOrder' element={<StitchingComplete />} />
          <Route path='/stitching/delieverOrder' element={<StitchingDeliever />} />
          <Route path='/stitching/deliver/view' element={<StitchingDeliverView />} />
          <Route path='/stitching/cancelled/list' element={<StitchingCancelList />} />


          <Route path='/readyMade' element={<ReadyMade />} />
          <Route path='/readyMade/newOrder' element={<ReadyMadeNewOrders />} />
          <Route path='/readyMade/newOrder/view' element={<ReadyMAdeOrderView />} />
          <Route path='/readyMade/confirmOrder' element={<ReadyMadeConfirmOrders />} />
          <Route path='/readyMade/completeOrder' element={<ReadyMadeCompleteOrders />} />
          <Route path='/readyMade/delieverOrder' element={<ReadyMadeDelieverOrders />} />

          <Route path='/specialRequest' element={<SpecialRequest />} />
          <Route path="/specialRequest/view" element={<SpecialrequestView />} />
          <Route path='/specialRequest/Create' element={<AddSpecialRequest />} />
          <Route path='/specialRequest/delivered/all' element={<SpecialRequestDeliveredAll />} />
          <Route path='/specialRequest/deliver/view' element={<SpecialRequestDeliverView/>} />
          <Route path='/specialRequest/cancelled/all' element={<SpecialRequestCancelledAll />} />


          <Route path='/sale' element={<Sale />} />

          <Route path='/products' element={<Products />} />
          <Route path='/stitching/products' element={<AllStitchingProducts />} />
          <Route path='/stitching/products/view' element={<ViewStitchingProduct />} />

          <Route path='/readyMade/products' element={<AllReadyMadeProducts />} />
          <Route path='/readyMade/products/view' element={<ViewReadyMadeProducts />} />


          <Route path='delivered/all' element={<DeliveredAll />} />
          <Route path='/cancelled/all' element={<CancelledAll/>} />

          <Route path='/riders' element={<Riders/>} />


          <Route path='/all' element={<AllViewScreen />} />
          <Route path='/specific' element={<SpecificProductScreen />} />
          <Route path={`/order/:id`} element={<StitchingOrderScreen />} />
          <Route path="/ready-to-wear/:type" element={<ViewReadytoWear />} />
          <Route path='/ready-to-wear/specific/:id' element={<SpecificReadytoWearScreen />} />
          <Route path='/stitching-design/:type' element={<ViewDesignScreen />} />
          <Route path='/stitching/collection/:ctg' element={<StitchingCollection />} />
          <Route path='/ready-to-wear/collection/:Title' element={<ViewReadytoWear />} />
          <Route path='/orders/tracking' element={<OrderTracking />} />
          <Route path='/order/history' element={<OrdersRecord />} />

          {/* This is for pages */}

          <Route path='/pages/exchange' element={<Exchange />} />
          <Route path='/pages/contact' element={<Contact />} />
          <Route path='/pages/about_us' element={<AboutUs />} />
        </Routes>
      </BrowserRouter>

      {/* <FooterTest /> */}


    </div >
  );
}

export default App;
