import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../Configuration/Api';

function StitchingDeliever() {
    const navigate = useNavigate()
    const [stitchingOrders, setStitchingOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const getOrders = async () => {
        console.log("Get USer");
        fetch(`${config.baseurl}/stitching/order/deliever/all`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {
                setStitchingOrders(responseData.Response)
                console.log(responseData.Response);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };
    useEffect(() => {
        getOrders();
    }, []);




    return (
        <div>
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                Stitching All Deliever Orders
            </div>

            <table className="container table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Order Number</th>
                        <th scope="col">From</th>
                        <th scope="col">Name</th>
                        <th scope="col">Design Cost</th>
                        <th scope="col">Delivery</th>
                        <th scope="col">Extra</th>
                        <th scope="col">Total</th>
                        <th scope="col">Product</th>
                        <th scope="col">View</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        stitchingOrders.map((item, index) => {
                            return (
                                <tr >
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.OrderNumber}</td>
                                    <td>{item.From}</td>
                                    <td>{item.Order.name || item.User.Name }</td>
                                    <td><strong>{item.Product.Charge_Price}</strong></td>
                                    <td>{item.DC}</td>
                                    <td>{item.Charges}</td>
                                    <td><strong>{item.TotalBill}</strong></td>
                                    <td><img src={item.Product.Images[0]} width={120} height={60} /></td>
                                    <td>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            navigate("/stitching/deliver/view", {
                                                state: {
                                                    Order: item,
                                                    updateto: ""
                                                }
                                            })
                                        }}>
                                            View
                                        </button>
                                    </td>
                                    

                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div>
    )
}

export default StitchingDeliever