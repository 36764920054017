import React from 'react'
import { config } from '../Configuration/Api';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Sale() {
    const [stitchingSale, setStitchingSale] = useState({})
    const [readymadeSale, setReadymadeSale] = useState({})
    const [specialSale, setSpecialSale] = useState({})
    const navigate = useNavigate()

    const getSalesSpecialRequest = async () => {
        // ${config.baseurl}
        // /http://localhost:4000
        fetch(`${config.baseurl}/stitching/order/specialRequest/deliever/all`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {

                setSpecialSale(responseData)


                return

                // console.log(responseData.Response.lenght);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };
    const getSalesStitching = async () => {
        // ${config.baseurl}
        // /http://localhost:4000
        fetch(`${config.baseurl}/stitching/order/deliever/all`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {
                console.log("dsadsad");
                console.log(responseData);
                setStitchingSale(responseData)
                console.log(stitchingSale);

                return

                // console.log(responseData.Response.lenght);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };
    const getSalesReadyMade = async () => {

        fetch(`${config.baseurl}/ready_made/order/deliever/all`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {

                setReadymadeSale(responseData)
                console.log(responseData);

                return

                // console.log(responseData.Response.lenght);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };
    useEffect(() => {

        getSalesReadyMade();
        getSalesStitching();
        getSalesSpecialRequest()
    }, [0]);


    return (
        <div className="text-center">
            <div style={{
                backgroundColor: "grey",
                // height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
            }}>
                Stitching Sale Record
            </div>

            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#1d3557",
                    margin: "auto",
                    marginTop: 5
                }} onClick={() => {
                    navigate("/stitching/delieverOrder")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        Total Orders
                        <br />
                        <b>
                            {stitchingSale.TotalOrder}
                        </b>
                    </span>


                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#0077b6",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        Product Charge
                        <br />
                        <b>
                            {stitchingSale.TotalSale}
                        </b>
                    </span>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#2a9d8f",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Delieveries

                        <br />
                        <b>
                            {stitchingSale.Delieveries}
                        </b>
                    </span>
                </div>

                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "blue",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Extra Charge

                        <br />
                        <b>
                            {stitchingSale.ExtraCharges}
                        </b>
                    </span>
                </div>

            </div>

            <div className="row align-items-center mt-2" >
                <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                    backgroundColor: "#2c6e49",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Stitching Total  Revenue

                        <br />
                        <b>
                            {stitchingSale.Revenue_From_Stitching} Rs
                        </b>
                    </span>
                </div>
            </div>


            {/* special request */}

            <div style={{
                backgroundColor: "grey",
                // height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                marginTop: "1%"
            }}>
                Special Request Sale Record
            </div>

            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#1d3557",
                    margin: "auto",
                    marginTop: 5
                }} onClick={() => {
                    navigate("/specialRequest/delivered/all")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        Total Orders
                        <br />
                        <b>
                            {specialSale.TotalOrder}
                        </b>
                    </span>


                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#0077b6",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        Product Charge
                        <br />
                        <b>
                            {specialSale.TotalSale}
                        </b>
                    </span>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#2a9d8f",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Delieveries

                        <br />
                        <b>
                            {specialSale.Delieveries}
                        </b>
                    </span>
                </div>

            </div>

            <div className="row align-items-center mt-2" >
                <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                    backgroundColor: "#2c6e49",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Stitching Total  Revenue

                        <br />
                        <b>
                            {specialSale.Revenue_From_SpecialRequest} Rs
                        </b>
                    </span>
                </div>
            </div>

            <div style={{
                backgroundColor: "grey",
                // height: "60px",
                width: "100%",
                color: "white",
                marginTop: "10px",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
            }}>
                ReadyMade Sale
            </div>

            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-4 col-lg-4" style={{
                    backgroundColor: "#5465ff",
                    margin: "auto",
                    marginTop: 5
                }} onClick={() => {
                    navigate("/readyMade/delieverOrder")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        Total Orders
                        <br />
                        <b>
                            {readymadeSale.TotalOrder}
                        </b>
                    </span>


                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4" style={{
                    backgroundColor: "#780116",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        Total Sale
                        <br />
                        <b>
                            {readymadeSale.TotalSale}
                        </b>
                    </span>
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4" style={{
                    backgroundColor: "#156064",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Delieveries

                        <br />
                        <b>
                            {readymadeSale.Delieveries}
                        </b>
                    </span>
                </div>

            </div>

            <div className="row align-items-center mt-2" >
                <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                    backgroundColor: "#2c6e49",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        ReadyMade Revenue

                        <br />
                        <b>
                            {readymadeSale.Revenue_From_Readymade} Rs
                        </b>
                    </span>
                </div>
            </div>


            <div className="row align-items-center mt-4" >
                <div className="col-12 col-sm-12 col-md-10 col-lg-8" style={{
                    backgroundColor: "tomato",
                    margin: "auto",
                    marginTop: 5

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Total Revenue

                        <br />
                        <b>
                            {readymadeSale.Revenue_From_Readymade + stitchingSale.Revenue_From_Stitching + specialSale.Revenue_From_SpecialRequest} Rs
                        </b>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Sale