import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
function StitchingDeliverView() {


    const location = useLocation()
    const orderData = location.state.Order
    const date = new Date(orderData.createdAt)


    return (
        <div >
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                Stitching Order History
            </div>

            <div className='container mt-1' style={{
                backgroundColor: "black",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                // textAlign: "center"


            }}>
                Product Details
            </div>
            <div className='row align-items-center container mt-2 ' style={{
                margin: "auto"
            }}>

                <div className='col'>
                    <table className=" table ">
                        <thead>
                            <tr>
                                <th >Design Name</th>
                                <th >Design No</th>
                            </tr>
                            <tr>
                                <td>{orderData.Product.Name}</td>
                                <td>{orderData.Product.Design_SKU}</td>
                            </tr>
                            <tr>
                                <th >Cost</th>
                                <th >Product Gender</th>
                            </tr>
                            <tr>
                                <td>{orderData.Product.Charge_Price}<strong> Rs</strong></td>
                                <td>{orderData.Product.main_category.general_category.Name}</td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='col'>
                    <img src={orderData.Product.Images[0]} className="border" width={"100%"} height={200} />
                </div>
            </div>


            <table className="container table mt-1">
                <thead>
                    <tr>
                        <th>DesignType</th>
                        <th >Fabric Required</th>
                        <th>From</th>
                        <th>Status</th>
                    </tr>
                    <tr>
                        <td>{orderData.Product.DesignType}</td>
                        <td>{orderData.Product.FabricRequired}</td>
                        <td>{orderData.From}</td>
                        <td>{orderData.Status}</td>
                    </tr>

                    <tr>
                        <th>
                            <b>
                                ORDER DATE
                            </b>
                        </th>
                    </tr>
                    <tr>
                        <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                    </tr>
                </thead>
            </table>

            <div className='container mt-1' style={{
                backgroundColor: "black",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                // textAlign: "center"


            }}>
                User Details
            </div>

            {

                <div>
                    <table className=" table container table-striped ">
                        <thead>
                            <tr>
                                <th >Customer Name</th>
                                <th >Customer Gender</th>
                            </tr>
                            <tr>
                                <td>{orderData.Order.name || orderData.User.Name}</td>
                                <td>{"No" || orderData.User.Gender}</td>
                            </tr>
                            <tr>
                                <th >Email</th>
                                <th >Phone</th>
                            </tr>
                            <tr>
                                <td>{orderData.Order.email || orderData.User.Email}</td>
                                <td>{orderData.Order.phone || orderData.User.Phone}</td>
                            </tr>
                        </thead>
                    </table>

                    <div className='container mt-1' style={{
                        backgroundColor: "black",
                        height: "60px",
                        width: "100%",
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        // textAlign: "center"


                    }}>
                        Order description
                    </div>

                    <table className=" table container table-striped ">
                        <thead>
                            <tr>
                                <th >Order Size</th>
                                <th >Order Number</th>
                            </tr>
                            <tr>


                                <td>
                                    {
                                        orderData.From == "App" ?
                                            JSON.parse(orderData.Order).Size
                                       
                                            :
                                            orderData.Order.size
                                    }
                                </td>


                                <td>{orderData.OrderNumber}  </td>
                            </tr>
                            <tr>
                                <th >Address</th>
                                <th >Requirement</th>
                            </tr>
                            <tr>
                                <td>
                                    {
                                        orderData.From == "App" ?
                                            JSON.parse(orderData.Order).Address
                                            :
                                            orderData.Order.address
                                    }
                                </td>
                                <td>
                                    {
                                        orderData.From == "App" ?
                                            JSON.parse(orderData.Order).Requirements
                                            :
                                            orderData.Order.requirement
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th >Notes</th>
                            </tr>
                            <tr>
                                <td >{orderData.Note}{"\n"}
                                    <br />
                                </td>
                            </tr>

                            <tr>
                                <th >Measurement</th>

                            </tr>
                            <tr>

                                <td>
                                    {
                                        orderData.From == "App" ?
                                            <p>
                                                {JSON.stringify(JSON.parse(orderData.Order).CustomeMeasurement)}
                                            </p>
                                            :
                                            "N/A"
                                    }
                                </td>
                            </tr>
                            <h3>Location</h3>
                            <tr>
                                <th >Longitude</th>
                                <th >Latitude</th>
                            </tr>
                            <tr>
                                <td>
                                    {
                                        orderData.From == "App" ?
                                            JSON.parse(orderData.Order).Location.longitude
                                            :
                                            "N/A"
                                    }
                                </td>
                                <td>
                                    {
                                        orderData.From == "App" ?
                                            JSON.parse(orderData.Order).Location.latitude
                                            :
                                            "N/A"
                                    }
                                </td>

                            </tr>
                        </thead>
                    </table>
                    {

                        // only show bill in completed state

                        <div>
                            <div className='container mt-3 mb-3' style={{
                                backgroundColor: "green",
                                height: "60px",
                                width: "100%",
                                color: "white",
                                fontSize: 34,
                                fontWeight: "bold",
                                fontFamily: "initial",
                                // textAlign: "center"


                            }}>
                                Payment
                            </div>
                            <table className=" table container table-striped  table-bordered border-primary ">
                                <thead>

                                    <tr>
                                        <th>Design  Charges  </th>
                                        <th>Delivery Charges </th>
                                        <th>Extra Charges  </th>
                                    </tr>
                                    <tr>
                                        <td>{orderData.Product.Charge_Price}</td>
                                        <td>{orderData.DC}</td>
                                        <td>{orderData.Charges}</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Extra Charges Comment
                                        </th>
                                        <td>


                                            <b>
                                                {
                                                    orderData.BillNote
                                                }
                                            </b>

                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            Sub total
                                        </th>
                                        <td>

                                            <b>
                                                {orderData.Product.Charge_Price} + {orderData.DC} + {orderData.Charges}
                                                = {orderData.TotalBill}
                                            </b>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            Discount
                                        </th>
                                        <td>
                                            <b>0.0</b>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>
                                            Total Bill
                                        </th>
                                        <td>
                                            <b>
                                                {orderData.TotalBill}
                                            </b>
                                        </td>
                                    </tr>

                                </thead>
                            </table>
                        </div>

                    }


                </div>





            }
        </div>
    )
}

export default StitchingDeliverView