import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { config } from '../Configuration/Api';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useNavigate } from 'react-router-dom';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

function Trending({ trend }) {
    console.log("trend", trend);
    // const [list, setlist] = useState([])
    // const [loading, setLoading] = useState(true);

    const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);
    // useEffect(() => {

    //     axios
    //         .get(`${config.baseurl}/stitching/sub/all/products`)
    //         .then(data => {
    //             //  console.log(data.data.Response);
    //             setlist(data.data.Response)
    //             setLoading(false)
    //         }

    //         )
    //         .catch(error => console.log(error));

    // }, [])

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    return (
        <div>
            <h3 style={{
                marginTop: "30px",
                marginBottom: "20px",
                textAlign: "center"
            }}>
                <span style={{
                    color: "#222222",
                    fontSize: "30px",
                    fontWeight: 600,
                    lineHeight: "30px",
                    // textAlign:"center"
                }}>
                    Trending
                </span>
            </h3>
            <ScrollMenu >
                {trend.map((item, index) => {
                    // console.log(item);
                    return (
                        <Card
                            // itemId={id} // NOTE: itemId is required for track items
                            // title={id}
                            data={item}
                            index={index}
                            key={item}
                            trend={trend}
                            onClick={handleClick(index)}
                            selected={isItemSelected(index)}
                        />
                    )
                }
                )}
            </ScrollMenu>
        </div>


    )
}

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        // <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        //     Left
        // </Arrow>
        <button onClick={() => scrollPrev()}>
            asdas
        </button>

    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
        // <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
        //     Right
        // </Arrow>


        <button disabled={isLastItemVisible} onClick={() => scrollNext()}>
            Right
        </button>
    );
}


function Card({ onClick, selected, title, itemId, data, trend, index }) {
    const visibility = React.useContext(VisibilityContext);
    const navigate = useNavigate()
    const [hovered, setHovered] = useState(false)
    const [img,setImg] = useState(4)
    function MouseOver(event) {
        // event.target.style.backgroundColor = "black";
        setHovered(true)
        setImg(3)
    }
    function MouseOut(event) {
        // event.target.style.backgroundColor = 'grey';
        setHovered(false)
        setImg(4)
    }
    return (
        <div style={{

        }} className="m-1" onClick={() => {
            navigate("/specific", { state: { single: data, alItems: trend } })
        }} onMouseOver={MouseOver} onMouseOut={MouseOut}>
            <div className="card" style={{
                width: "18rem",
                height: window.innerHeight / 2,
                backgroundColor: `${hovered ? 'black' : `white`}`,
                color: `${hovered ? 'white' : `black`}`,
                fontWeight: "bold"
            }}>
                <img src={data.Images[0]} className="card-img-top"
                    height={window.innerHeight / 3}
                    alt="..." />
                <div className="card-body">
                    <h3 className="card-title" style={{ fontWeight: "bold" }}>{data.Name}</h3>
                    <span>
                        Rs {data.Actual_Price}.00
                    </span>

                </div>
            </div>
        </div>
        //        <div
        //        onClick={() => onClick(visibility)}
        //        style={{
        //            width: '160px',
        //        }}
        //        tabIndex={0}
        //    >
        //        <div className="card">
        //            <div>{title}</div>
        //            <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        //            <div>selected: {JSON.stringify(!!selected)}</div>
        //        </div>
        //        <div
        //            style={{
        //                height: '200px',
        //            }}
        //        />
        //    </div>
    );
}

export default Trending

{/* <div style={{}} className="row" >
                {
                    list.map((item, index) => (

                        <div className='col-3'>
                            <img src={item.Images[index]} style={{ width: window.innerWidth / 2, height: window.innerHeight / 3 }} />
                        </div>

                    ))
                }
            </div> */}