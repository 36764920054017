
import Stitch from "../Assests/Images/stitch.jpg"
import readyMade from "../Assests/Images/readyMade.jpg"
import { json, useNavigate } from 'react-router-dom'
import { useEffect, useState } from "react"


function Home() {
    const navigate = useNavigate()
    const [data, setData] = useState()
    const parse = localStorage.getItem("data")
    const getUser = JSON.parse(parse)
  


    return (
        <div>

            <div className="row " >
                {
                    getUser.Stitching ?
                        <div>
                            <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                                height: "400px",
                                // width: "100%",
                                textAlign: "center",
                                paddingTop: "180px",
                                backgroundColor: "#1d3557",
                                borderRadius: 10,
                                margin: "auto"


                            }} onClick={() => {
                                navigate("/stitching")
                            }}>
                                <span style={{
                                    color: "white",
                                    fontSize: 24,
                                    fontWeight: "bold"
                                }}>
                                    Stitching
                                </span>
                            </div>

                            <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                                height: "400px",
                                // width: "100%",
                                textAlign: "center",
                                paddingTop: "180px",
                                backgroundColor: "#8F3666",
                                borderRadius: 10,
                                margin: "auto"


                            }} onClick={() => {
                                navigate("/specialRequest")
                            }}>
                                <span style={{
                                    color: "white",
                                    fontSize: 24,
                                    fontWeight: "bold"
                                }}>
                                    Special Request
                                </span>
                            </div>
                        </div>
                        :
                        ""
                }
                {
                    getUser.ReadyMade ?
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                            // backgroundImage: `url(${readyMade})`,
                            height: "400px",
                            // width: "100vw",
                            backgroundColor: "#0077b6",
                            textAlign: "center",
                            paddingTop: "180px",
                            borderRadius: 10,
                            margin: "auto"
                        }} onClick={() => {
                            navigate("/readyMade")
                        }}>
                            <span style={{
                                color: "white",
                                fontSize: 24,
                                fontWeight: "bold",

                            }}>
                                ReadyMade
                            </span>
                        </div>
                        :
                        ""
                }

            </div>


            <div className="row mt-2" >
                {
                    getUser.Products ?
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                            height: "400px",
                            // width: "100%",
                            textAlign: "center",
                            paddingTop: "180px",
                            backgroundColor: "#610f7f",
                            borderRadius: 10,
                            margin: "auto"


                        }} onClick={() => {
                            navigate("/products")
                        }}>
                            <span style={{
                                color: "white",
                                fontSize: 24,
                                fontWeight: "bold"
                            }}>
                                Products
                            </span>
                        </div>
                        :
                        ""
                }
            </div>
            <div className="row mt-2" >
                {
                    getUser.Riders ?
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                            height: "400px",
                            // width: "100%",
                            textAlign: "center",
                            paddingTop: "180px",
                            backgroundColor: "blue",
                            borderRadius: 10,
                            margin: "auto"


                        }} onClick={() => {
                            navigate("/riders")
                        }}>
                            <span style={{
                                color: "white",
                                fontSize: 24,
                                fontWeight: "bold"
                            }}>
                                Riders
                            </span>
                        </div>
                        :
                        ""
                }
            </div>

            <div className="row mt-2" >
                {
                    getUser.DeliveredAll ?
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                            height: "400px",
                            // width: "100%",
                            textAlign: "center",
                            paddingTop: "180px",
                            backgroundColor: "tomato",
                            borderRadius: 10,
                            margin: "auto"


                        }} onClick={() => {
                            navigate("/delivered/all")
                        }}>
                            <span style={{
                                color: "white",
                                fontSize: 24,
                                fontWeight: "bold"
                            }}>
                                DeliveredAll
                            </span>
                        </div>
                        :
                        ""
                }
            </div>
            <div className="row mt-2" >
                {
                    getUser.Sale ?
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                            height: "400px",
                            // width: "100%",
                            textAlign: "center",
                            paddingTop: "180px",
                            backgroundColor: "#386641",
                            borderRadius: 10,
                            margin: "auto"


                        }} onClick={() => {
                            navigate("/sale")
                        }}>
                            <span style={{
                                color: "white",
                                fontSize: 24,
                                fontWeight: "bold"
                            }}>
                                Sales
                            </span>
                        </div>
                        :
                        ''
                }
                {/* <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                    // backgroundImage: `url(${readyMade})`,
                    height: "400px",
                    // width: "100vw",
                    backgroundColor: "#6f1d1b",
                    textAlign: "center",
                    paddingTop: "180px",
                    borderRadius: 10,
                    margin: "auto"
                }} onClick={() => {
                    navigate("/readyMade")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 24,
                        fontWeight: "bold",

                    }}>
                        Special Request Orders
                    </span>
                </div> */}
            </div>
            <div className="row mt-2" >
                {
                    getUser.CancelledAll ?
                        <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                            height: "400px",
                            // width: "100%",
                            textAlign: "center",
                            paddingTop: "180px",
                            backgroundColor: "grey",
                            borderRadius: 10,
                            margin: "auto"


                        }} onClick={() => {
                            navigate("/cancelled/all")
                        }}>
                            <span style={{
                                color: "white",
                                fontSize: 24,
                                fontWeight: "bold"
                            }}>
                                Cancelled All
                            </span>
                        </div>
                        :
                        ""
                }
            </div>

            {/* <div className="row align-items-center" >
                    <div className="col" style={{
                        // backgroundImage: `url(${Stitch})`,
                        backgroundColor: "grey",
                        height: "400px",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundRepeat: 'no-repeat',
                        position: "relative"

                    }} onClick={() => {
                        navigate("/stitching/products")
                    }}>
                        <span style={{
                            color: "white",
                            fontSize: 34,
                            fontWeight: "bold",
                            fontFamily: "initial",
                            position: "absolute",
                            top: "40%",
                            left: "40%"
                        }}>
                            Stitching Products
                        </span>
                    </div>
                    <div className="col" style={{
                        // backgroundImage: `url(${readyMade})`,
                        backgroundColor: "pink",
                        height: "400px",
                        width: "100%",
                        backgroundSize: "cover",
                        backgroundRepeat: 'no-repeat',
                        position: "relative"

                    }} onClick={() => {
                        navigate("/readyMade/products")
                    }}>
                        <span style={{
                            color: "black",
                            fontSize: 34,
                            fontWeight: "bold",
                            fontFamily: "initial",
                            position: "absolute",
                            top: "40%",
                            left: "40%"
                        }}>
                            ReadyMade Products
                        </span>
                    </div>

                </div> */}


        </div>
    )
}

export default Home

{/* <Crousel all={stitching} />


<Trending trend={stitching} />


<Design ctg={stitching} />


<Category />



<Trending trend={stitching} /> */}