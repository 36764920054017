import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Trending from '../Components/Trending';
import { config } from '../Configuration/Api';


const SingleViewComponent = ({ item, list, discount }) => {

    const navigate = useNavigate()
    const [hovered, setHovered] = useState(false)
    const [image, setImage] = useState(4)
    function MouseOver(event) {
        // event.target.style.backgroundColor = "black";
        setImage(3)
        setHovered(true)
    }
    function MouseOut(event) {
        // event.target.style.backgroundColor = 'grey';
        setHovered(false)
        setImage(4)
        setHovered(false)
    }
    return (
        <div onClick={() => {
            console.log(item);
            navigate("/specific", { state: { single: item, allItems: list } })
        }} style={{
            backgroundColor: "#ddd",
            borderRadius: 10
        }} onMouseOver={MouseOver}
            onMouseOut={MouseOut}>
            <img src={item.Images[image]} height={window.innerHeight / 2} width={'100%'} style={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10
            }} />

            <div style={{
                position: "absolute",
                top: 5,
                right: 10,
                backgroundColor: "bisque",
                color: "black",
                width: 65,
                height: 65,
                borderRadius: 30,
                fontWeight: "500",
                // textAlign: "center",


            }} className="hover">
                <p style={{
                    marginTop: 15
                }}>
                    {discount}% off
                </p>
            </div>

            <span style={{

                textTransform: "capitalize",
                color: "#222222",
                fontSize: "20px",
                fontWeight: '500',
                lineHeight: "30px",

            }}>
                {item.Name}
            </span>


            <div className='row '>
                <span style={{
                    textTransform: "capitalize",
                    color: "#222222",
                    fontSize: "22px",
                    fontWeight: '500',
                    lineHeight: "30px",
                }} className='col-sm-12 col-md-6' >
                    Rs:{item.Charge_Price}
                </span>
                <span style={{
                    textTransform: "capitalize",
                    color: "#222222",
                    fontSize: "16px",
                    lineHeight: "30px",
                    textDecorationLine: "line-through"
                }} className='col-sm-12 col-md-6'>
                    {item.Actual_Price}.00 off
                </span>
            </div>

        </div>
    )
}
function ViewDesignScreen() {
    //this is for all ready made woman products
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true);


    const navigate = useNavigate()
    const params = useParams()

    useEffect(() => {

        axios
            .get(`${config.baseurl}/stitching/sub/all/products`)
            .then(data => {
                console.log(data.data.Response);
                // const filter = data.data.Response.filter()
                const filterList = data.data.Response?.filter((item) => item.main_category.general_category.Name == params.type);
                setList(filterList)
                setLoading(false)
            }

            )
            .catch(error => console.log(error));

    }, [])
    return (
        <div style={{
            textAlign: "center"
        }}>

            <h3 style={{
                marginTop: "30px",
                marginBottom: "20px",
                textAlign: "center"
            }}>
                <span style={{
                    color: "#222222",
                    fontSize: "40px",
                    fontWeight: '500',
                    lineHeight: "30px",
                    textTransform: "capitalize"
                }}>
                    {params.type} stitching Design
                </span>

            </h3>

            <div className='row mt-5 mb-5'>


                {list.map((item, index) => {
                    //console.log(item);
                    const actual = item.Actual_Price
                    const charge = item.Charge_Price
                    const discount = parseInt((actual - charge) * 100 / actual)
                    return (
                        <div className='col-6 col-sm-6 col-md-4 col-lg-3 mt-2 position-relative '>

                            <SingleViewComponent item={item} discount={discount} list={list} />
                            {/* <div onClick={() => {
                                console.log(item);
                                navigate("/specific", { state: { single: item, allItems: list } })
                            }} style={{
                                backgroundColor: "#ddd",
                                borderRadius: 10
                            }}>
                                <img src={item.Images[4]} height={window.innerHeight / 2} width={'100%'} style={{
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10
                                }} />

                                <div style={{
                                    position: "absolute",
                                    top: 5,
                                    right: 10,
                                    backgroundColor: "bisque",
                                    color: "black",
                                    width: 65,
                                    height: 65,
                                    borderRadius: 30,
                                    fontWeight: "500",
                                    // textAlign: "center",


                                }} className="hover">
                                    <p style={{
                                        marginTop: 15
                                    }}>
                                        {discount}% off
                                    </p>
                                </div>

                                <span style={{

                                    textTransform: "capitalize",
                                    color: "#222222",
                                    fontSize: "20px",
                                    fontWeight: '500',
                                    lineHeight: "30px",

                                }}>
                                    {item.Name}
                                </span>


                                <div className='row '>
                                    <span style={{
                                        textTransform: "capitalize",
                                        color: "#222222",
                                        fontSize: "22px",
                                        fontWeight: '500',
                                        lineHeight: "30px",
                                    }} className='col-sm-12 col-md-6' >
                                        Rs:{item.Charge_Price}
                                    </span>
                                    <span style={{
                                        textTransform: "capitalize",
                                        color: "#222222",
                                        fontSize: "16px",
                                        lineHeight: "30px",
                                        textDecorationLine: "line-through"
                                    }} className='col-sm-12 col-md-6'>
                                        {item.Actual_Price}.00 off
                                    </span>
                                </div>

                            </div> */}
                        </div>
                    )
                }
                )}
            </div>

            <Trending trend={list} />

        </div>
    )
}

export default ViewDesignScreen