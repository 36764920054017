import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { config } from '../Configuration/Api';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import { useNavigate } from 'react-router-dom';

const getItems = () =>
    Array(20)
        .fill(0)
        .map((_, ind) => ({ id: `element-${ind}` }));

function NewArrivals() {
    const [list, setlist] = useState([])
    const [loading, setLoading] = useState(true);

    const [items, setItems] = React.useState(getItems);
    const [selected, setSelected] = React.useState([]);
    const [position, setPosition] = React.useState(0);
    useEffect(() => {

        axios.get(`${config.baseurl}/ready_made/products/all`)
            .then(data => {
                // console.log(data.data.Response);
                setlist(data.data.Response)
                setLoading(false)
            }

            )
            .catch(error => console.log(error));

    }, [])

    const isItemSelected = (id) => !!selected.find((el) => el === id);

    const handleClick =
        (id) =>
            ({ getItemById, scrollToItem }) => {
                const itemSelected = isItemSelected(id);

                setSelected((currentSelected) =>
                    itemSelected
                        ? currentSelected.filter((el) => el !== id)
                        : currentSelected.concat(id)
                );
            };

    return (
        <div>
            <h3 style={{
                marginTop: "30px",
                marginBottom: "20px",
                textAlign:"center"
            }}>
                <span style={{
                    color: "#222222",
                    fontSize: "30px",
                    fontWeight: 600,
                    lineHeight: "30px",
                }}>
                    New Arrivals
                </span>
            </h3>
            <ScrollMenu >
                {list.map((item, index) => {
                    // console.log(item);
                    return (
                        <Card
                            // itemId={id} // NOTE: itemId is required for track items
                            // title={id}
                            data={item}
                            index={index}
                            key={item}
                            onClick={handleClick(index)}
                            selected={isItemSelected(index)}
                            list={list}
                        />
                    )
                }
                )}
            </ScrollMenu>
        </div>


    )
}

function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);

    return (
        // <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        //     Left
        // </Arrow>
        <button onClick={() => scrollPrev()}>
            asdas
        </button>

    );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

    return (
        // <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
        //     Right
        // </Arrow>


        <button disabled={isLastItemVisible} onClick={() => scrollNext()}>
            Right
        </button>
    );
}


function Card({ onClick, selected, title, itemId, data, index, list }) {
    const visibility = React.useContext(VisibilityContext);
    const navigate = useNavigate()
    return (
        <div style={{

        }} className="m-1" onClick={() => {
            navigate(`/ready-to-wear/specific/${data._id}`, { state: { single: data, allItems: list } })
        }}>
            <div className="card" style={{ width: "18rem", height: window.innerHeight / 2 }}>
                <img src={data.Images[0]} className="card-img-top" height={window.innerHeight / 3} alt="..." />
                <div className="card-body">
                    <h3 className="card-title">{data.Name}</h3>
                    <span>
                        Rs {data.Actual_Price}.00
                    </span>

                </div>
            </div>
        </div>
    );
}

export default NewArrivals

