import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Trending from "../Components/Trending"


const SingleViewComponent = ({ item, list, discount }) => {

    const navigate = useNavigate()
    const [hovered, setHovered] = useState(false)
    const [image, setImage] = useState(4)
    function MouseOver(event) {
        // event.target.style.backgroundColor = "black";
        setImage(3)
        setHovered(true)
    }
    function MouseOut(event) {
        // event.target.style.backgroundColor = 'grey';
        setHovered(false)
        setImage(4)
        setHovered(false)
    }
    return (
        <div onClick={() => {
            console.log(item);
            navigate("/specific", { state: { single: item, allItems: list } })
        }} style={{
            backgroundColor: "#ddd",
            borderRadius: 10
        }} onMouseOver={MouseOver}
        onMouseOut={MouseOut}>
            <img src={item.Images[image]} height={window.innerHeight / 2} width={'100%'} style={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10
            }}  />

            <div style={{
                position: "absolute",
                top: 5,
                right: 10,
                backgroundColor: "bisque",
                color: "black",
                width: 65,
                height: 65,
                borderRadius: 30,
                fontWeight: "500",
                // textAlign: "center",


            }} className="hover">
                <p style={{
                    marginTop: 15
                }}>
                    {discount}% off
                </p>
            </div>

            <span style={{

                textTransform: "capitalize",
                color: "#222222",
                fontSize: "20px",
                fontWeight: '500',
                lineHeight: "30px",

            }}>
                {item.Name}
            </span>


            <div className='row '>
                <span style={{
                    textTransform: "capitalize",
                    color: "#222222",
                    fontSize: "22px",
                    fontWeight: '500',
                    lineHeight: "30px",
                }} className='col-sm-12 col-md-6' >
                    Rs:{item.Charge_Price}
                </span>
                <span style={{
                    textTransform: "capitalize",
                    color: "#222222",
                    fontSize: "16px",
                    lineHeight: "30px",
                    textDecorationLine: "line-through"
                }} className='col-sm-12 col-md-6'>
                    {item.Actual_Price}.00 off
                </span>
            </div>

        </div>
    )
}
function AllViewScreen({ }) {
    const location = useLocation()
    const list = location.state.all
    console.log("All", location.state.all);

    return (
        <div style={{
            textAlign: "center"
        }}>

            <div style={{
                // position:"absolute"
            }}>
                <h3 style={{
                    marginTop: "30px",
                    marginBottom: "20px"
                }}>
                    <span style={{
                        color: "#222222",
                        fontSize: "40px",
                        fontWeight: '500',
                        lineHeight: "30px",
                        textTransform: "capitalize"
                    }}>
                        All Design
                    </span>

                </h3>
                {/* <span style={{
                color: "#222222",
                fontSize: "20px",
                fontWeight: 400,
                // lineHeight: "30px",
                textTransform: "capitalize"
            }}>
                (Shop by category)
            </span> */}
                <div className='row m-1' style={{
                    height: window.innerHeight / 1
                }}>
                    {list.map((item, index) => {
                        //console.log(item);
                        const actual = item.Actual_Price
                        const charge = item.Charge_Price
                        const discount = parseInt((actual - charge) * 100 / actual)
                        return (
                            <div className='col-6 col-sm-6 col-md-4 col-lg-3 mt-2 position-relative '>
                                <SingleViewComponent item={item} ist={list} discount={discount} />
                            </div>
                        )
                    }
                    )}

                </div>
                {/* <Trending  trend={list}/> */}

            </div>
        </div>
    )
}

export default AllViewScreen