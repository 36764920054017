import React, { useEffect } from 'react'
import { useState } from 'react'
import { config } from '../../Configuration/Api';
import { useNavigate } from 'react-router-dom';

function SpecialRequest() {
    const [list, setList] = useState([])
    const [request, setRequests] = useState("New")
    const navigate = useNavigate()
    const getOrders = async () => {
        // ${config.baseurl}
        fetch(`${config.baseurl}/stitching/order/specialRequest/all`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {

                console.log(responseData);

                if (responseData.success == true) {
                    setList(responseData.response)
                }

                return

                // console.log(responseData.Response.lenght);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };


    useEffect(() => {
        getOrders();
    }, []);
    let ne = 0
    let comp = 0
    let conf = 0
    let deli = 0
    let cancel = 0
    const newOrders = []
    const confrimOrders = []
    const completeOrders = []
    const deliverOrders = []
    const cancelOrders = []
    return (
        <div className="text-center">

            <div style={{
                backgroundColor: "grey",
                // height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
            }}>
                Special Requests
            </div>

            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#1d3557",
                    margin: "auto",
                    marginTop: 5
                }} onClick={() => {

                    setRequests("New")

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        New

                        {
                            list.map((order) => {

                                if (order.Status == "Ordered") {
                                    ne++
                                    newOrders.push(order)
                                    return
                                }


                            })

                        }

                        <br />
                        <b>
                            {ne}
                        </b>
                    </span>


                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#0077b6",
                    margin: "auto",
                    marginTop: 5

                }} onClick={() => {

                    setRequests("Confirm")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        Confirm

                        {
                            list.map((order) => {

                                if (order.Status == "Confirmed") {
                                    conf++
                                    confrimOrders.push(order)
                                    return
                                }

                            })
                        }
                        <br />
                        <b>
                            {conf}
                        </b>
                    </span>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#2a9d8f",
                    margin: "auto",
                    marginTop: 5

                }} onClick={() => {

                    setRequests("Complete")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Completed
                        {
                            list.map((order) => {

                                if (order.Status == "Completed") {
                                    comp++
                                    completeOrders.push(order)
                                    return
                                }

                            })
                        }
                        <br />
                        <b>
                            {comp}
                        </b>
                    </span>
                </div>
                <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "#2b9f5f",
                    margin: "auto",
                    marginTop: 5

                }} onClick={() => {
                    navigate("/specialRequest/Create")

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Create New
                        <br />
                        <b>
                            ...
                        </b>
                    </span>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-3 col-lg-3" style={{
                    backgroundColor: "red",
                    margin: "auto",
                    marginTop: 5

                }} onClick={() => {
                    // navigate("/stitching/completeOrder")
                    setRequests("Cancelled")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Cancelled
                        {
                            list.map((order) => {

                                if (order.Status == "Cancelled") {
                                    cancel++
                                    cancelOrders.push(order)
                                    return
                                }

                            })
                        }
                        <br />
                        <b>
                            {cancel}
                        </b>
                    </span>
                </div> */}
            </div>


            {/* this is for table  */}

            <div>
                <div style={{
                    backgroundColor: "tomato",
                    // height: "60px",
                    width: "100%",
                    color: "white",
                    fontSize: 34,
                    margin: 5,
                    fontWeight: "bold",
                    fontFamily: "initial",
                }}>
                    {request} Requests
                </div>
                <table className="container table table-striped">

                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Request Number</th>
                            <th scope="col">Request Gender</th>
                            <th scope="col">From</th>
                            <th scope="col">Name</th>
                            <th>Date</th>

                        </tr>
                    </thead>
                    <tbody>

                        {
                            request === "New" ?
                                newOrders.map((item, index) => {
                                    const date = new Date(item.createdAt)
                                    return (
                                        <tr onClick={() => {

                                            navigate("/specialRequest/view", {
                                                state: {
                                                    Order: item,
                                                    updateto: "confirmed"
                                                }
                                            })
                                        }}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.OrderNumber}</td>
                                            <td style={{ textTransform: "capitalize" }}>{item.Order.Gender}</td>
                                            <td>{item.From}</td>
                                            <td>
                                                {
                                                    item.Order.Name || item.User.Name
                                                }
                                            </td>
                                            <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                                        </tr>
                                    )
                                })
                                :
                                request === "Confirm" ?
                                    confrimOrders.map((item, index) => {
                                        const date = new Date(item.createdAt)
                                        return (
                                            <tr onClick={() => {

                                                navigate("/specialRequest/view", {
                                                    state: {
                                                        Order: item,
                                                        updateto: "completed"
                                                    }
                                                })
                                            }}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item.OrderNumber}</td>
                                                <td style={{ textTransform: "capitalize" }}>{item.Order.Gender}</td>
                                                <td>{item.From}</td>
                                                <td>
                                                    {
                                                        item.Order.Name || item.User.Name
                                                    }
                                                </td>
                                                <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                                            </tr>
                                        )
                                    })
                                    :
                                    request === "Complete" ?
                                        completeOrders.map((item, index) => {
                                            const date = new Date(item.createdAt)
                                            return (
                                                <tr onClick={() => {

                                                    navigate("/specialRequest/view", {
                                                        state: {
                                                            Order: item,
                                                            updateto: "delievered"
                                                        }
                                                    })
                                                }}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.OrderNumber}</td>
                                                    <td style={{ textTransform: "capitalize" }}>{item.Order.Gender}</td>
                                                    <td>{item.From}</td>
                                                    <td>
                                                        {
                                                            item.Order.Name || item.User.Name
                                                        }
                                                    </td>

                                                    <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>



                                                </tr>
                                            )
                                        })
                                        :
                                        request == "Cancelled" ?
                                            cancelOrders.map((item, index) => {
                                                return (
                                                    <tr onClick={() => {

                                                        navigate("/specialRequest/view", {
                                                            state: {
                                                                Order: item,
                                                                updateto: ""
                                                            }
                                                        })
                                                    }}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.OrderNumber}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{item.Order.Gender}</td>
                                                        <td>{item.From}</td>
                                                        <td>{item.Order.Name || item.User.Name}</td>
                                                        <td>{item.Status}</td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            ''

                        }

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default SpecialRequest