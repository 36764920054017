import React from 'react'
import { useNavigate } from 'react-router-dom'
function CancelledAll() {

    const navigate = useNavigate()
    return (
        <div>
            {/* <nav aria-label="breadcrumb" className='container bg-light p-1 mt-1'>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/products">Products</a></li>

                </ol>
            </nav> */}
            <div className="row mt-2" >
                <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                    height: "400px",
                    // width: "100%",
                    textAlign: "center",
                    paddingTop: "180px",
                    backgroundColor: "#386641",
                    borderRadius: 10,
                    margin: "auto"


                }} onClick={() => {
                    navigate("/stitching/cancelled/list")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 24,
                        fontWeight: "bold"
                    }}>
                        Stitching Cancel Order
                    </span>
                </div>
                <div className="col-12 col-sm-12 col-md-10 col-lg-10" style={{
                    // backgroundImage: `url(${readyMade})`,
                    height: "400px",
                    // width: "100vw",
                    backgroundColor: "#6f1d1b",
                    textAlign: "center",
                    paddingTop: "180px",
                    borderRadius: 10,
                    margin: "auto"
                }} onClick={() => {
                    navigate("/specialRequest/cancelled/all")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 24,
                        fontWeight: "bold",

                    }}>
                        Special Request Cancel
                    </span>
                </div>
            </div></div>
    )
}

export default CancelledAll