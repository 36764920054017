import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../Configuration/Api';

function StitchingComplete() {
    const [empty, setEmpty] = useState(false)
    const navigate = useNavigate()
    const [stitchingOrders, setStitchingOrders] = useState([])
    const [loading, setLoading] = useState(false)
    const getOrders = async () => {
        console.log("Get USer");
        fetch(`${config.baseurl}/stitching/order/complete/all`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {
                setStitchingOrders(responseData.Response)
                // console.log(responseData.Response);
                if (responseData.Response.length == 0 ) {
                    console.log("enter");
                    setEmpty(true)
                }
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };
    useEffect(() => {
        getOrders();
    }, []);
    const showStatus = async (id) => {
        setLoading(true)
        console.log(id);

        await fetch(`${config.baseurl}/stitching/order/delievered/${id}`,
        ).then(
            (response) => response.json()
        ).catch((err) => {
            // alert("something went wrong. Please try again")
            console.log(err);
        }).then(responseData => {
            console.log(responseData)
            setLoading(false)

        })
    }



    return (
        <div>
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                Stitching Complete Orders
            </div>
            {
                empty ?
                    <div style={{
                        height: "60px",
                        width: "100%",
                        color: "black",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        textAlign: "center"
                    }}>
                       <span>Empty!<br></br></span>
                        No Order Complete yet
                    </div>
                    :

            <table className="container table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Order Number</th>
                        <th scope="col">From</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Cost</th>
                        <th scope="col">DesignType</th>
                        <th scope="col">Product</th>
                        <th scope="col">View</th>
                        <th scope="col">Update</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        stitchingOrders.map((item, index) => {
                            return (
                                <tr >
                                    <th scope="row">{index + 1}</th>
                                    <td>{item.OrderNumber}</td>
                                    <td>{item.From}</td>
                                    <td>{item.Product.main_category.general_category.Name}</td>
                                    <td>{item.Product.Charge_Price}<strong> Rs</strong></td>
                                    <td>{item.Product.DesignType}</td>
                                    <td><img src={item.Product.Images[0]} width={120} height={60} /></td>
                                    <td>
                                        <button type="button" class="btn btn-primary" onClick={() => {
                                            navigate("/stitching/newOrder/view", {
                                                state: {
                                                    Order: item,
                                                    updateto: "delievered"
                                                }
                                            })
                                        }}>
                                            View
                                        </button>
                                    </td>
                                    <td>
                                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                            style={{ width: "40%", margin: "auto", fontWeight: 'bold' }}
                                            data-bs-target="#staticBackdro" onClick={() => {
                                                showStatus(item._id)
                                            }}>
                                            Deliever
                                        </button>

                                        <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Order Status</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">

                                                        {
                                                            loading ?
                                                                <span>
                                                                    Loading...
                                                                </span>
                                                                :

                                                                <span>
                                                                    <b>Order has been updated </b>
                                                                    <br />

                                                                    <br />
                                                                    <a href='/pages/contact'>
                                                                        go to deliever
                                                                    </a>
                                                                </span>

                                                        }
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
}
        </div>
    )
}

export default StitchingComplete