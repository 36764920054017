import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../Configuration/Api';

function AllReadyMadeProducts() {
    const [empty, setEmpty] = useState(false)
    const navigate = useNavigate()
    const [products, setProducts] = useState([])
    const getOrders = async () => {

        fetch(`${config.baseurl}/ready_made/products/all/notrandom`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {
                setProducts(responseData.Response)
                console.log("come", responseData.Response);

                if (responseData.Response.length == 0) {
                    console.log("enter");
                    setEmpty(true)
                }
                // console.log(responseData.Response.lenght);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };
    useEffect(() => {
        getOrders();
    }, []);
    // getOrders();


    return (
        <div>
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>


                All ReadyMade Products
            </div>

            {
                empty ?
                    <div style={{
                        height: "60px",
                        width: "100%",
                        color: "black",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        textAlign: "center"
                    }}>
                        <span>Empty!<br></br></span>
                        No new order is available
                    </div>
                    :
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Cost</th>
                                <th scope="col">Collection</th>
                                <th scope="col">Total Stock</th>
                                <th scope="col">Remaining Stock</th>
                                <th scope="col">Sold out</th>
                                <th scope="col">Product</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                products.map((item, index) => {
                                    const sell = item.TotalStock - item.SoldOut
                                    return (
                                        <tr onClick={() => {

                                            navigate("/readyMade/products/view", {
                                                state: {
                                                    Product: item,
                                                }
                                            })
                                        }}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.Name}</td>
                                            <td>{item.SKU}</td>
                                            <td>{item.main_category.type}</td>
                                            <td>{item.Charge_Price}<strong> Rs</strong></td>
                                            <td>{item.main_category.Title}</td>
                                            <td>{item.TotalStock}</td>
                                            <td>{item.SoldOut}</td>
                                            <td>{sell}</td>
                                            <td><img src={item.Images[0]} width={120} height={60} /></td>
                                            {/* 
                                            <td>{item.main_category.general_category.Name}</td>
                                            <td>{item.Charge_Price}<strong> Rs</strong></td>
                                            <td>{item.main_category.Name}</td>
                                            <td>{item.DesignType}</td>
                                            <td><img src={item.Images[0]} width={120} height={60} /></td> */}


                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>

            }

        </div>
    )
}

export default AllReadyMadeProducts