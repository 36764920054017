import React from 'react'
import Stitch from "../Assests/Images/stitch.jpg"
import readyMade from "../Assests/Images/readyMade.jpg"
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { config } from '../Configuration/Api';
function Riders() {
    const navigate = useNavigate()
    // http://localhost:400/stitching/order/all
    const [empty, setEmpty] = useState(false)
    const [stitchingOrders, setStitchingOrders] = useState([])
    const [orders, setOrders] = useState("New")
    const getOrders = async () => {

        fetch(`${config.baseurl}/stitching/order/all`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {
                setStitchingOrders(responseData.Response)
                // console.log("come",responseData.Response);

                if (responseData.Response.length == 0) {
                    console.log("enter");
                    setEmpty(true)
                }

                return

                // console.log(responseData.Response.lenght);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };


    useEffect(() => {
        getOrders();
    }, []);
    let ne = 0
    let comp = 0
    let conf = 0
    const newOrders = []
    const confrimOrders = []
    const completeOrders = []
    return (

        <div className="text-center">
            <div style={{
                backgroundColor: "grey",
                // height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
            }}>
                Riders Dashboard
            </div>
            <div className="row align-items-center" >
                <div className="col-12 col-sm-12 col-md-4 col-lg-4" style={{
                    backgroundColor: "#1d3557",
                    margin: "auto",
                    marginTop: 5
                }} onClick={() => {
                    // navigate("/stitching/newOrder")
                    setOrders("New")

                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        New
                        {
                            stitchingOrders.map((order) => {

                                if (order.Status == "Ordered") {
                                    ne++
                                    newOrders.push(order)
                                    return
                                }


                            })

                        }
                        <br />
                        <b>
                            {ne}
                        </b>
                    </span>


                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4" style={{
                    backgroundColor: "#0077b6",
                    margin: "auto",
                    marginTop: 5

                }} onClick={() => {
                    // navigate("/stitching/confirmOrder")
                    setOrders("Confirm")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",

                    }}>
                        Confirm

                        {
                            stitchingOrders.map((order) => {

                                if (order.Status == "Confirmed") {
                                    conf++
                                    confrimOrders.push(order)
                                    return
                                }

                            })
                        }
                        <br />
                        <b>
                            {conf}
                        </b>
                    </span>
                </div>
                <div className="col-12 col-sm-12 col-md-4 col-lg-4" style={{
                    backgroundColor: "#2a9d8f",
                    margin: "auto",
                    marginTop: 5

                }} onClick={() => {
                    // navigate("/stitching/completeOrder")
                    setOrders("Complete")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                    }}>
                        Completed
                        {
                            stitchingOrders.map((order) => {

                                if (order.Status == "Completed") {
                                    comp++
                                    completeOrders.push(order)
                                    return
                                }

                            })
                        }
                        <br />
                        <b>
                            {comp}
                        </b>
                    </span>
                </div>

            </div>

            {
                empty ?
                    <div style={{
                        height: "60px",
                        width: "100%",
                        color: "black",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        textAlign: "center"
                    }}>
                        <span>Empty!<br></br></span>
                        No new order is available
                    </div>
                    :
                    <div>
                        <div style={{
                            backgroundColor: "tomato",
                            // height: "60px",
                            width: "100%",
                            color: "white",
                            fontSize: 34,
                            margin: 5,
                            fontWeight: "bold",
                            fontFamily: "initial",
                        }}>
                            {orders} Orders
                        </div>
                        <table className="container table table-striped">

                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Order Number</th>
                                    <th scope="col">From</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Date</th>
                                    {/* <th scope="col">DesignType</th> */}
                                    <th scope="col">Product</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    orders === "New" ?
                                        newOrders.map((item, index) => {
                                            const date = new Date(item.createdAt)
                                            return (
                                                <tr onClick={() => {

                                                    navigate("/stitching/deliver/view", {
                                                        state: {
                                                            Order: item,
                                                            updateto: ""
                                                        }
                                                    })
                                                }}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.OrderNumber}</td>
                                                    <td>{item.From}</td>
                                                    <td>{item.From == "App" ? item.User.Name : item.Order.name}</td>
                                                    <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                                                    {/* <td>{item.Product.DesignType}</td> */}
                                                    <td><img src={item.Product.Images[0]} width={120} height={60} /></td>
                                                </tr>
                                            )
                                        })
                                        :
                                        orders === "Confirm" ?
                                            confrimOrders.map((item, index) => {
                                                const date = new Date(item.createdAt)
                                                return (
                                                    <tr onClick={() => {

                                                        navigate("/stitching/deliver/view", {
                                                            state: {
                                                                Order: item,
                                                                updateto: ""
                                                            }
                                                        })
                                                    }}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.OrderNumber}</td>
                                                        <td>{item.From}</td>
                                                        <td>{item.From == "App" ? item.User.Name : item.Order.name}</td>
                                                        <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                                                        {/* <td>{item.Product.DesignType}</td> */}
                                                        <td><img src={item.Product.Images[0]} width={120} height={60} /></td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            completeOrders.map((item, index) => {
                                                const date = new Date(item.createdAt)
                                                return (
                                                    <tr onClick={() => {

                                                        navigate("/stitching/deliver/view", {
                                                            state: {
                                                                Order: item,
                                                                updateto: ""
                                                            }
                                                        })
                                                    }}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.OrderNumber}</td>
                                                        <td>{item.From}</td>
                                                        <td>{item.From == "App" ? item.User.Name : item.Order.name}</td>
                                                        <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                                                        {/* <td>{item.Product.DesignType}</td> */}
                                                        <td><img src={item.Product.Images[0]} width={120} height={60} /></td>
                                                    </tr>
                                                )
                                            })
                                }

                            </tbody>
                        </table>
                    </div>


            }

        </div>
    )
}

export default Riders