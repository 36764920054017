import React, { useState } from 'react'
import { contactMsg } from '../Configuration/ApiIntegration'

function Contact() {
    const [name, setName] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [response, setReponse] = useState()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    return (
        <div style={{
            // textAlign:"center"
        }}>
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact bg-light">
                <div className="container" data-aos="fade-up">
                    <div className="section-title mb-3 ">
                        <h2 style={{ textAlign: "center" }}>Contact</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className="info">
                                <div className="address">
                                    <i class="bi bi-geo-alt" style={{ color: "green", fontWeight: "bold" }}></i>
                                    <h4>Location:</h4>
                                    <p>
                                        Plot#39 , Street#39 <br />
                                        Sammanabad, Lhr 54000<br />
                                        Pakistan
                                    </p>
                                </div>
                                <div className="email">
                                    <i className="bi bi-envelope" ></i>
                                    <h4>Email:</h4>
                                    <p>wecare@thefashionwise.com</p>
                                </div>
                                <div className="phone">
                                    <i class="bi bi-phone-vibrate"></i>
                                    <h4>Call:</h4>
                                    <p>+92 326 432 1272</p>
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameBorder={0} style={{ border: 0, width: '100%', height: '290px' }} allowFullScreen />
                            </div>
                        </div>
                        <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                            {/* action="forms/contact.php" className="php-email-form" method="post" role="form" */}
                            <form onSubmit={async (e) => {
                                e.preventDefault();
                                if (e) {
                                    console.log("true");
                                    setShow(true)
                                    console.log(name, email);
                                    const response = await contactMsg(name, email, phone, message);
                                    console.log(response);
                                    if (response.success == true) {

                                        setReponse(response.message)
                                        alert("Thanks for contact us!")
                                    }
                                    else {
                                        alert("Sorry we cannot send your message!")
                                    }
                                } else {
                                    console.log("false");
                                    alert('Something went wrong!')
                                }

                            }}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Your Name</label>
                                        <input type="text" name="name" className="form-control" id="name" required
                                            onChange={(event) => setName(event.target.value)} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Your Email</label>
                                        <input type="email" className="form-control" name="email" id="email" required
                                            onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Phone</label>
                                    <input type="text" className="form-control" name="subject" id="subject" required
                                        onChange={(event) => setPhone(event.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Message</label>
                                    <textarea className="form-control" name="message" rows={10} required defaultValue={""}
                                        onChange={(event) => setMessage(event.target.value)} />
                                </div>
                                {/* <div className="my-3">
                                    <div className="">Your message has been sent. Thank you!</div>
                                </div> */}
                                <div className="text-center mt-2">
                                    <button type='submit' className='btn btn-secondary'
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop" >
                                        Send Message
                                    </button>
                                  
                                </div>
                                {
                                    show ?


                                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Contact US</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">

                                                        {
                                                            loading ?
                                                                <span>
                                                                    Loading...
                                                                </span>
                                                                :
                                                                <span>
                                                                    <b>
                                                                        {response}
                                                                    </b>
                                                                    <br />
                                                                    {/* <a href='/pages/contact'>
                                                                        Read more
                                                                    </a> */}
                                                                </span>
                                                        }
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Contact Section */}
        </div>
    )
}

export default Contact