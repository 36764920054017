import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Trending from '../Components/Trending';
import { config } from '../Configuration/Api';

function SpecificReadytoWearScreen() {
    const location = useLocation();
    const navigate = useNavigate()
    const singleProduct = location.state.single
    const AllProduct = location.state.allItems
    const actual = singleProduct.Actual_Price
    const charge = singleProduct.Charge_Price
    const discount = parseInt((actual - charge) * 100 / actual)
    const [slec, setSlec] = useState(0)
    const [view, setView] = useState("details");
    const [size, setSize] = useState("small")
    const [modal, setModal] = useState(false)
    const [choose, setChoose] = useState(false)
    // const data = singleProduct.Size;
    // console.log(data);
    // const [state, setState] = useState(data.map((item, index) => {
    //     var newState = `s${index}`
    //     return newState = item.name
    // }));

    // function onChange(name, val) {
    //     setState(prevState => ({ ...prevState, [name]: val }));
    // }

    const buyNow = () => {
        console.log("dasdsad");
        if (!choose) {
            alert("Please choose the size first")
            return
        }

        navigate(`/order/${singleProduct._id}`, { state: { size: size, product: singleProduct, all: AllProduct } })

        // setModal(true)
    }
    return (
        <div>
            {/* this is for screen breadcrum for easily change the screen */}

            <div>
                <nav aria-label="breadcrumb" className='' style={{
                    width: '90%',
                    //height: 60,
                    backgroundColor: "#eee",
                    margin: "auto"
                }}>
                    <ol className="breadcrumb" style={{
                        paddingTop: 20,
                        paddingBottom: 10,
                        margin: "auto",
                        paddingLeft: 10
                    }}>
                        <li className="breadcrumb-item pl-5"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/"> Ready to Wears</a></li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {singleProduct.Name}
                        </li>
                    </ol>
                </nav>
            </div>

            <div className='row' style={{
                borderWidth: 2,
                borderColor: "black",
                width: '90%',
                margin: "auto",
            }}>

                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2' style={{
                    borderWidth: 2, borderColor: "black"
                }}>
                    <img src={singleProduct.Images[slec]} height={window.innerHeight / 2} width={'100%'} style={{
                        borderRadius: 10
                    }} />

                </div>

                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6'>

                    <div className='row ' >
                        <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 '>

                            {
                                singleProduct.Images.map((img, index) => {
                                    return (

                                        <img src={img} height={"100"} width={"100"} style={{
                                            borderRadius: 20,
                                            marginLeft: '1%',
                                            marginTop: "1%",
                                            borderWidth: 2,
                                            borderColor: slec == index ? "black" : "red"
                                        }} onClick={() => {
                                            console.log(index);
                                            setSlec(index)
                                        }}
                                            className="col-sm-3 col-md-8 col-lg-8 col-xl-8  mt-2"
                                        />

                                    )

                                })
                            }
                        </div>
                        <div className='col-sm-12 col-md-8 col-lg-8 col-xl-8 bg-light '>
                            <div className='mt-3  '>
                                <span style={{
                                    textTransform: "capitalize",
                                    color: "#222222",
                                    fontSize: "24px",
                                    fontWeight: 'bold',
                                    lineHeight: "30px",
                                    //marginTop:20,
                                    //backgroundColor: "red",

                                }}>
                                    {singleProduct.Name}
                                </span>
                                <div>
                                    <span style={{
                                        textTransform: "capitalize",
                                        color: "#222222",
                                        fontSize: "20px",
                                        fontWeight: '600',
                                        lineHeight: "30px",
                                    }}>
                                        Rs {singleProduct.Charge_Price}.00
                                    </span>
                                    <span style={{
                                        textTransform: "capitalize",
                                        color: "#222222",
                                        fontSize: "20px",
                                        fontWeight: '400',
                                        lineHeight: "30px",
                                        marginLeft: 5,
                                        textDecorationLine: "line-through"

                                    }}>
                                        {singleProduct.Actual_Price}.00
                                    </span>
                                    <span style={{
                                        textTransform: "capitalize",
                                        color: "tomato",
                                        fontSize: "18px",
                                        fontWeight: '400',
                                        lineHeight: "30px",
                                        marginLeft: 5,
                                        backgroundColor: "grey",
                                        borderRadius: 5
                                    }}>
                                        {discount}% off
                                    </span>
                                </div>

                                <div>
                                    <span style={{
                                        textTransform: "capitalize",
                                        color: "#222222",
                                        fontSize: "20px",
                                        fontWeight: '600',
                                        lineHeight: "30px",
                                    }}>
                                        Status :
                                    </span>

                                    <span style={{
                                        textTransform: "capitalize",
                                        color: "black",
                                        fontSize: "18px",
                                        fontWeight: '400',
                                        lineHeight: "30px",
                                        marginLeft: 5,

                                    }}>
                                        active
                                    </span>
                                </div>

                                <div className='row mt-3 mb-3 justify-content-center'>
                                    <span className='shadow-lg  ml-2' style={{
                                        backgroundColor: size == 'small' ? '#000' : "#ddd",
                                        color: size == 'small' ? 'white' : "black",
                                        width: 80,
                                        height: 80,
                                        borderRadius: 40,
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        paddingTop: 25,

                                    }} onClick={() => {
                                        setSize("small")
                                        setChoose(true)
                                    }}>
                                        Small
                                    </span>
                                    <span className='shadow-lg  ml-2' style={{
                                        backgroundColor: size == 'medium' ? '#000' : "#ddd",
                                        color: size == 'medium' ? 'white' : "black",
                                        width: 80,
                                        height: 80,
                                        borderRadius: 40,
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        paddingTop: 25,
                                        marginLeft: 10

                                    }} onClick={() => {
                                        setSize('medium')
                                        setChoose(true)
                                    }}>
                                        Medium
                                    </span>

                                    <span className='shadow-lg  ml-2' style={{
                                        backgroundColor: size == 'large' ? '#000' : "#ddd",
                                        color: size == 'large' ? 'white' : "black",
                                        width: 80,
                                        height: 80,
                                        borderRadius: 40,
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        paddingTop: 25,
                                        marginLeft: 10

                                    }} onClick={() => {
                                        setSize("large")
                                        setChoose(true)
                                    }}>
                                        Large
                                    </span>
                                </div>



                                {/* This is for buy now button */}
                                <button style={{
                                    // backgroundColor: 'black',
                                    color: "black",
                                    fontSize: 20,
                                    fontWeight: '700',
                                    width: "98%",
                                    marginTop: "5%",
                                    marginBottom: "5%"
                                }} onClick={buyNow} type="button"  >
                                    Buy Now
                                </button>

                                <div className='row mt-3'>

                                    <span className='shadow-lg  ml-2' style={{
                                        backgroundColor: view == 'details' ? '#ddd' : "#eee",
                                        color: view == 'details' ? '#000' : "black",
                                        width: 100,
                                        height: 100,
                                        borderRadius: 50,
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        paddingTop: 35,

                                    }} onClick={() => {
                                        setView("details")
                                    }}>
                                        Details
                                    </span>
                                    <span className='shadow-lg' style={{
                                        backgroundColor: view == 'mesurements' ? '#ddd' : "#eee",
                                        color: view == 'mesurements' ? '#000' : "black",
                                        width: 100,
                                        height: 100,
                                        borderRadius: 50,
                                        fontSize: 16,
                                        fontWeight: "bold",
                                        paddingTop: 35,
                                        marginLeft: 5
                                    }} onClick={() => {
                                        setView("mesurements")
                                    }}>
                                        Size Chart
                                    </span>


                                </div>
                                <div className='mt-3'>
                                    {
                                        view == "details" ?
                                            <div>
                                                <span>
                                                    {singleProduct.Description}
                                                </span>
                                                <div className='row align-self-start'>
                                                    <div className='col '>
                                                        <p className='mt-4 '>
                                                            <strong style={{ fontSize: 18, textDecorationLine: "underline" }}>
                                                                Product Details <br />
                                                            </strong>
                                                            <span>
                                                                Ready to Wear
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>


                                                <strong style={{ fontSize: 18, textDecorationLine: "underline" }}>
                                                    Fabric
                                                </strong>
                                                <span>
                                                    {"\t"} Organza
                                                </span>
                                                <p className=''>
                                                    <strong style={{ fontSize: 18, textDecorationLine: "underline" }}>
                                                        Colour
                                                    </strong>
                                                    <span>
                                                        {"\t"} tomato
                                                    </span>
                                                </p>


                                                <strong style={{ fontSize: 18, textDecorationLine: "underline" }}>
                                                    Collection :
                                                </strong>
                                                <span>
                                                    {"\t\t"}  {singleProduct.main_category.Title}
                                                </span>
                                                <br />
                                                <strong style={{ fontSize: 18, textDecorationLine: "underline" }}>
                                                    Type:  {"\t\t"}
                                                </strong>
                                                <span>
                                                    For  {singleProduct.main_category.type} wear
                                                </span>

                                                <p className='mt-5'>
                                                    <strong style={{ fontSize: 18, textDecorationLine: "underline" }}>
                                                        Note: <br />
                                                    </strong>
                                                    <span>
                                                        Poduct color may slighty different from the acutal color but you can check it
                                                        at delievery time.
                                                    </span>
                                                </p>


                                            </div>
                                            :
                                            <div>
                                                {/* this is for size boxes */}
                                                <div className='row mt-5'>



                                                </div>


                                            </div>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            {/* This is for trending products */}

            {
                AllProduct == null ?
                    ''
                    :
                    <Trending trend={AllProduct} />

            }
        </div >
    )
}

export default SpecificReadytoWearScreen