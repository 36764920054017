import React from 'react'
import { useState } from 'react'
import { config } from '../Configuration/Api';
import { useNavigate } from 'react-router-dom';

function LoginPage() {

    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("")
    const navigate = useNavigate()
    const login = async (e) => {
        e.preventDefault()
        await fetch(`${config.baseurl}/admin/login`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: email,
                    password: pass
                })
            }).then(
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again")
                console.log(err);
            }).then(responseData => {

                if (responseData.success == true) {
                    localStorage.setItem("data", JSON.stringify(responseData.Response))
                    navigate("/dashboard")
                } else {
                    alert("Your credentials are wrong")
                }
            })
    }
    return (
        <div style={{
            height: "100vh",
            backgroundColor: '#eee'
        }}>
            <div className='text-center container' style={{
                height: "50vh",
                backgroundColor: "#ddd",

            }}>
                <form
                    onSubmit={(e) => { login(e) }}

                >
                    <label style={{
                        marginTop: "10%",
                        color: "black",
                        fontWeight: "bold",
                        fontSize: 20
                    }}>
                        Email
                        <br />
                        <input
                            name="emailOrUsername"
                            type="text"
                            value={email}
                            placeholder='Enter Email'
                            onChange={(val) => setEmail(val.target.value)}
                        />
                    </label>
                    <br />
                    <label style={{
                        marginTop: "2%",
                        color: "black",
                        fontWeight: "bold",
                        fontSize: 20
                    }}>
                        Password
                        <br />
                        <input
                            name="password"
                            type="password"
                            placeholder='Enter Password'
                            value={pass}
                            onChange={(val) => setPass(val.target.value)}
                        />
                    </label>
                    <br />
                    <button type="submit" style={{ marginTop: '3%', width: 150 }}>Log in</button>
                </form>
            </div>
        </div>
    )
}

export default LoginPage