import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { config } from '../../Configuration/Api'
import { OrderStatus } from '../../Configuration/ApiIntegration'

function ViewReadyMadeProducts() {
    const location = useLocation()
    const product = location.state.Product
    const [edit, setEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState(product.Name);
    const [chargePrice, setChargePrice] = useState(product.Charge_Price);
    const [actualPrice, setActualPrice] = useState(product.Actual_Price);
    const [specification, setSpecification] = useState(product.Specification)
    const [description, setDescription] = useState(product.Description);
    const [fabricName, setFabricName] = useState(product.FabricName)
    const [size, setSize] = useState(product.size)
    const [image0, setImage0] = useState(product.Images[0])
    const [image1, setImage1] = useState(product.Images[1])
    const [image2, setImage2] = useState(product.Images[2])
    const [image3, setImage3] = useState(product.Images[3])
    const [image4, setImage4] = useState(product.Images[4])
    console.log(product.Images);
    const sell = product.TotalStock - product.SoldOut

    const updateDesign = async () => {
        setLoading(true)
        const response = await fetch(`http://localhost:4000/ready_made/product/update/${product._id}`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: name,
                    description: description,
                    specification: specification,
                    chargePrice: chargePrice,
                    actualPrice: actualPrice,
                    fabricName: fabricName,
                    size: size,
                    images: [
                        image0,
                        image1,
                        image2,
                        image3,
                        image4
                    ]
                })
            }).then(
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again")
                console.log(err);
            }).then(responseData => {
                console.log(responseData);
                setLoading(false)
                return responseData
            })

    }
    return (
        <div className=''>

            <nav aria-label="breadcrumb" className='container bg-light p-1 mt-1'>
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="/">Home</a></li>
                    <li class="breadcrumb-item"><a href="/products">Products</a></li>
                    <li class="breadcrumb-item"><a href="/readyMade/products">Readymade Product</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{product.Name}</li>
                </ol>
            </nav>
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                ReadyMade Product Details
            </div>

            <div className='row align-items-center container mt-2 ' style={{
                margin: "auto"
            }}>

                <div className='col'>
                    <table className=" table ">
                        <thead>
                            <tr>
                                <th >Products Name</th>
                                <th >Product SKU</th>
                            </tr>
                            <tr>
                                <td>
                                    {
                                        edit ? <input value={name} onChange={(val) => setName(val.target.value)} />
                                            :
                                            <p> {product.Name}</p>
                                    }
                                </td>
                                <td>{product.SKU}</td>
                            </tr>
                            <tr>
                                <th >Charge Price</th>
                                <th >Actutal Price</th>
                            </tr>
                            <tr>
                                <td>
                                    {
                                        edit ? <input value={chargePrice} onChange={(val) => setChargePrice(val.target.value)} />
                                            :
                                            <p> {product.Charge_Price} <strong> Rs</strong></p>
                                    }
                                </td>
                                <td>
                                    {
                                        edit ? <input value={actualPrice} onChange={(val) => setActualPrice(val.target.value)} />
                                            :
                                            <p> {product.Actual_Price} <strong> Rs</strong></p>
                                    }
                                </td>

                            </tr>
                            <tr>
                                <th >TotalStock</th>
                                <th >Soldout</th>
                            </tr>
                            <tr>
                                <td>
                                    {
                                        product.TotalStock
                                    }
                                </td>
                                <td>

                                    {
                                        sell
                                    }
                                </td>

                            </tr>
                        </thead>
                    </table>
                </div>
                <div className='col'>
                    <img src={product.Images[0]} className="border" width={"100%"} height={200} />
                </div>
            </div>


            <table className="container table mt-1">
                <thead>


                    <tr>
                        <th>
                            Specification
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {
                                edit ? <textarea value={specification} width={"150%"} style={{ width: "150%" }} onChange={(val) => setSpecification(val.target.value)} />
                                    :
                                    <p>{product.Specification}</p>
                            }

                        </td>
                    </tr>

                    <tr>
                        <th>
                            Description
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {
                                edit ? <textarea type='text' value={description} style={{ width: "150%" }} onChange={(val) => setDescription(val.target.value)} />
                                    :
                                    <p>{product.Description}</p>
                            }

                        </td>
                    </tr>


                    <tr>
                        <th>
                            Images
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {
                                edit ?
                                    <tr>
                                        <td>
                                            <textarea value={image0} style={{ width: "350%" }} onChange={(val) => setImage0(val.target.value)} />
                                            <textarea value={image1} style={{ width: "350%" }} onChange={(val) => setImage1(val.target.value)} />
                                            <br />
                                            <textarea value={image2} style={{ width: "350%" }} onChange={(val) => setImage2(val.target.value)} />
                                            <br />
                                            <textarea value={image3} style={{ width: "350%" }} onChange={(val) => setImage3(val.target.value)} />
                                            <br />
                                            <textarea value={image4} style={{ width: "350%" }} onChange={(val) => setImage4(val.target.value)} />
                                        </td>

                                    </tr>
                                    :
                                    <p>
                                        {image0}<br />
                                        {image1}<br />
                                        {image2}<br />
                                        {image3}<br />
                                        {image4}<br />
                                    </p>
                            }

                        </td>
                    </tr>

                    <tr>
                        <th>
                            Fabric name
                        </th>
                    </tr>
                    <tr>
                        <td>
                            {
                                edit ? <input value={fabricName} onChange={(val) => setFabricName(val.target.value)} />
                                    :
                                    <p>{fabricName}</p>
                            }

                        </td>
                    </tr>

                    <tr>
                        <th>
                            Size
                        </th>
                    </tr>
                    <tr>
                        {
                            edit ? <input value={size} width={"200%"} style={{ width: "150%" }} onChange={(val) => setSize(val.target.value)} />
                                :
                                <p>{size}</p>
                        }
                    </tr>

                    <tr>

                        <td> <img src={product.Images[0]} className="border" height={200} /></td>
                        <td> <img src={product.Images[1]} className="border" height={200} /></td>
                        <td><img src={product.Images[2]} className="border" height={200} /></td>
                        <td> <img src={product.Images[3]} className="border" height={200} /></td>
                        <td> <img src={product.Images[4]} className="border" height={200} /></td>
                    </tr>

                </thead>
            </table>


            {
                edit ?
                    <div className='row ' style={{ marginLeft: "30%" }}>
                        <div className='col-4 col-sm-4 col-md-2 col-lg-2 mb-5 mt-5'>
                            <button type="button" class="btn btn-primary"
                                style={{
                                    backgroundColor: "grey", margin: "auto", fontWeight: 'bold',
                                    textTransform: 'capitalize', color: "black"
                                }}

                                onClick={() => {
                                    setEdit(false)
                                }}
                            >
                                Cancel
                            </button>
                        </div>
                        <div className='col-3 col-sm-3 col-md-2 col-lg-2 mb-5 mt-5'>

                            <button type="button" class="btn btn-sceondary"
                                style={{
                                    backgroundColor: "green", margin: "auto", fontWeight: 'bold',
                                    textTransform: 'capitalize', color: "white"
                                }}

                                onClick={() => {
                                    // setEdit(true)
                                    updateDesign()
                                }}
                            >
                                {loading ? "Loading" : "Save"}
                            </button>
                        </div>
                    </div>
                    :
                    <button type="button" className="btn btn-primary mt-5 mb-5"
                        style={{
                            backgroundColor: "tomato", fontWeight: 'bold',
                            textTransform: 'capitalize', marginLeft: "45%", color: "white"
                        }}

                        onClick={() => {
                            setEdit(true)
                        }}
                    >
                        Edit Detail
                    </button>
            }


        </div>
    )
}

export default ViewReadyMadeProducts