import React from 'react'
import { useState } from 'react'
import { config } from '../../Configuration/Api'

function AddSpecialRequest() {

    const [gender, setGender] = useState("")
    const [design, setDesign] = useState("")
    const [requirement, setRequirement] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [loading, setLoading] = useState(false)

    const selectGender = (event) => {
        setGender(event.target.value)
    }
    const selectDesign = (event) => {
        setDesign(event.target.value)
    }

    const createNew = () => {
        console.log(name, email, phone, gender, design, requirement);
        if (!name || !email || !phone || !gender || !design || !requirement) {
            alert("Something is missing . Please check al the fields.")
            return
        }
        setLoading(true)
        fetch(`${config.baseurl}/stitching/order/specialRequest/admin/add`,
            {
                method: "POST",
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({

                    request: {
                        Gender: gender,
                        Design: design,
                        Requirement: requirement,
                        Name: name,
                        Email: email,
                        Phone: phone
                    }
                })
            }).then(
            
                (response) => response.json()
            ).catch((err) => {
                alert("something went wrong. Please try again")
                console.log(err);
            }).then(responseData => {
                // console.log(responseData);
                if (responseData.success == true) {

                    setLoading(false)
                    alert(`New Request Created ${responseData.response.OrderNumber} .`)
                    return
                }
                else {
                    setLoading(false)
                    alert("Error! Requirement is not created")
                }

            })
    }



    return (
        <div >
            <div className='text-center' style={{
                backgroundColor: "grey",
                // height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
            }}>
                Create New Special Request
            </div>

            <div className='text-center'>

                <h1>Name</h1>
                <input type='text' style={{ width: "300px" }} placeholder='Please enter name' onChange={(val) => setName(val.target.value)} />

                <h1>Email</h1>
                <input type="email" style={{ width: "300px" }} placeholder='Please enter email address' onChange={(val) => setEmail(val.target.value)} />

                <h1>Phone</h1>
                <input type="number" style={{ width: "300px" }} placeholder='Please enter phone number' onChange={(val) => setPhone(val.target.value)} />

            </div>
            <div className='container mt-5'>

                <div className='m-5'>
                    <h1>Gender</h1>
                    <select class="form-select" aria-label="Default select example" onChange={selectGender}>
                        <option selected> Please Select a Gender</option>
                        <option value={'male'}>Male</option>
                        <option value={"female"}>Female</option>
                    </select>
                </div>

                <div className='m-5'>
                    <h1>Design</h1>
                    <select class="form-select" aria-label="Default select example" onChange={selectDesign} >

                        <option selected> Please Select a design</option>
                        <option value={"kurta"}>kurta</option>
                        <option value={"waist coat"}>Waist coat</option>
                        <option value={"maxi"}>Maxi</option>
                        <option value={'others'}>Others</option>
                    </select>
                </div>

                <div className='m-5'>
                    <h1>Requirement</h1>
                    <textarea type="text" style={{ width: "50%" }}
                        placeholder='Please write requirement in  details'
                        onChange={(val) => setRequirement(val.target.value)} />
                </div>

                <div className='text-center'>
                    <button type="button" className="btn btn-primary"
                        style={{ width: "50%", margin: "auto", fontWeight: 'bold', textTransform: 'capitalize' }}
                        onClick={() => {
                            createNew()
                        }}>
                        {
                            loading ? ' Creating... ' : ' create new'
                        }
                    </button>


                </div>

            </div>



        </div>
    )
}

export default AddSpecialRequest