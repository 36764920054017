import React from 'react'
import Stitch from "../Assests/Images/stitch.jpg"
import readyMade from "../Assests/Images/readyMade.jpg"
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
function ReadyMade() {
    const navigate = useNavigate()

    return (

        <div className="text-center">
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",


            }}>
                ReadyMade Dashboard
            </div>
            <div className="row align-items-center" >
                <div className="col" style={{
                    backgroundColor: "#1d3557",
                    height: "300px",
                    width: "100%",
                    position: "relative"

                }} onClick={() => {
                    navigate("/readyMade/newOrder")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        position: "absolute",
                        top: "40%",
                        left: "40%"
                    }}>
                        New Orders
                    </span>
                </div>
                <div className="col" style={{
                    backgroundColor: "#0077b6",
                    height: "300px",
                    width: "100%",
                    position: "relative"

                }} onClick={() => {
                    navigate("/readyMade/confirmOrder")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        position: "absolute",
                        top: "40%",
                        left: "40%"
                    }}>
                        Confirm Orders
                    </span>
                </div>

            </div>

            <div className="row align-items-center " onClick={() => {
                // navigate("/stitching")
            }} >
                <div className="col" style={{
                    backgroundColor: "#2a9d8f",
                    height: "300px",
                    width: "100%",
                    position: "relative"

                }} onClick={() => {
                    navigate("/readyMade/completeOrder")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        position: "absolute",
                        top: "40%",
                        left: "40%"
                    }}>
                        Completed Orders
                    </span>
                </div>
                {/* <div className="col" style={{
                    backgroundColor: "#00b4d8",
                    height: "300px",
                    width: "100%",
                    position: "relative"

                }}  onClick={() => {
                    navigate("/readyMade/delieverOrder")
                }}>
                    <span style={{
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        position: "absolute",
                        top: "40%",
                        left: "40%"
                    }}>
                        Delivered Orders
                    </span>
                </div> */}

            </div>
        </div>
    )
}

export default ReadyMade