import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

function SpecialRequestDeliverView() {
    const location = useLocation()
    const orderData = location.state.Order
    const date = new Date(orderData.createdAt)

    return (
        <div >
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                SpecialRequest Details
            </div>

            <div className='container mt-1' style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                // textAlign: "center"


            }}>
                User Details
            </div>


            <div>
                <table className=" table container table-striped ">
                    <thead>
                        <tr>
                            <th >Customer Name</th>
                            <th >Customer Gender</th>
                            <th>From</th>
                        </tr>
                        <tr>
                            <td>{orderData.Order.Name || orderData.User.Name}</td>
                            <td>{orderData.Order.Gender || orderData.User.Gender}</td>
                            <td> {orderData.From} </td>
                        </tr>
                        <tr>
                            <th >Email</th>
                            <th >Phone</th>
                            <th>Status</th>
                        </tr>
                        <tr>
                            <td>{orderData.Order.Email || orderData.User.Email}</td>
                            <td>{orderData.Order.Phone || orderData.User.Phone}</td>
                            <td>{orderData.Status}</td>
                        </tr>
                        
                    </thead>
                </table>

                <div className='container mt-1' style={{
                    backgroundColor: "grey",
                    height: "60px",
                    width: "100%",
                    color: "white",
                    fontSize: 34,
                    fontWeight: "bold",
                    fontFamily: "initial",
                    // textAlign: "center"


                }}>
                    Request Details
                </div>
                <table className=" table container table-striped ">
                    <thead>
                        <tr>
                            <th >Request Gender</th>
                            <th >Request Number</th>
                            <th>Date</th>
                        </tr>
                        <tr>
                            <td>{orderData.Order.Gender}  </td>
                            <td>{orderData.OrderNumber}</td>
                            <td><strong>{date.getDate()}/{date.getMonth()}/{date.getFullYear()}</strong></td>
                        </tr>
                        <tr>
                            <th>Design Request</th>
                            <th >Requirement</th>
                        </tr>
                        <tr>
                            <td>
                                {orderData.Order.Design}
                            </td>
                            <td>
                                {orderData.Order.Requirement}
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Notes
                            </th>
                        </tr>
                        <tr>
                            <td >{orderData.Note}{"\n"}
                                <br />

                            </td>
                        </tr>
                    </thead>
                </table>

                {/* This is for special request bill */}


                <div>
                    <div className='container mt-3 mb-3' style={{
                        backgroundColor: "green",
                        height: "60px",
                        width: "100%",
                        color: "white",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        // textAlign: "center"


                    }}>
                        Payment

                    </div>

                    <table className=" table container table-striped  table-bordered border-primary ">
                        <thead>

                            <tr>

                                <th >Delivery Charges
                                    
                                </th>
                                <th >Design  Charges
                                   
                                </th>

                            </tr>
                            <tr>

                                <td>


                                    <p>
                                        {orderData.DC} Rs
                                    </p>
                                </td>

                                <td>
                                    <b>
                                        {
                                            orderData.Charges
                                        }
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Extra Charges Comment
                                </th>
                                <td>
                                    <b>
                                        {
                                            orderData.BillNote
                                        }
                                    </b>

                                </td>
                            </tr>

                            <tr>
                                <th>
                                    Sub total
                                </th>
                                <td>
                                    
                                            <b>
                                                {orderData.DC} + {orderData.Charges}
                                                = {orderData.TotalBill}
                                            </b>
                                    
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Discount
                                </th>
                                <td>
                                    <b>0.0</b>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    Total Bill
                                </th>
                                <td>
                                    <b>
                                        {orderData.TotalBill}
                                    </b>
                                </td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default SpecialRequestDeliverView