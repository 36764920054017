import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { config } from '../../Configuration/Api';

function ReadyMadeConfirmOrders() {
    const [empty, setEmpty] = useState(false)
    const navigate = useNavigate()
    const [readyMadeOrders, setReadyMadeOrders] = useState([])
    const getOrders = async () => {

        fetch(`${config.baseurl}/ready_made/order/confirm/all`, {
            method: "GET",
            mode: "cors",
            headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((response) =>
                response.json()
            ).then((responseData) => {
                setReadyMadeOrders(responseData.Response)
                console.log("come",responseData.Response);

                if (responseData.Response.length == 0) {
                    console.log("enter");
                    setEmpty(true)
                }
                // console.log(responseData.Response.lenght);
            })
            .catch((err) => {
                console.log("Error in api", err);
            });
    };
    useEffect(() => {
        getOrders();
    }, []);
    // getOrders();


    return (
        <div>
            <div style={{
                backgroundColor: "grey",
                height: "60px",
                width: "100%",
                color: "white",
                fontSize: 34,
                fontWeight: "bold",
                fontFamily: "initial",
                textAlign: "center"


            }}>
                ReadyMade Confirm Orders
            </div>

            {
                empty ?
                    <div style={{
                        height: "60px",
                        width: "100%",
                        color: "black",
                        fontSize: 34,
                        fontWeight: "bold",
                        fontFamily: "initial",
                        textAlign: "center"
                    }}>
                        <span>Empty!<br></br></span>
                        No new order is available
                    </div>
                    :
                    <table className="container table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Order Number</th>
                                <th scope="col">From</th>
                                <th scope="col">items</th>
                                <th scope="col">Cost</th>
                                <th scope="col">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                readyMadeOrders.map((item, index) => {
                                    return (
                                        <tr onClick={() => {

                                            navigate("/readyMade/newOrder/view", {
                                                state: {
                                                    Order: item,
                                                    updateto: "completed"
                                                }
                                            })
                                        }}>
                                            <th scope="row">{index + 1}</th>
                                            <td>{item.OrderNumber}</td>
                                            <td>{item.From}</td>
                                            <td>{item.Order.total}</td>
                                            <td>{item.Order.totalPrice}<strong> Rs</strong></td>
                                            <td>{item.Status}</td>
                                            {/* <td><img src={item.Product.Images[0]} width={120} height={60} /></td> */}
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>

            }

        </div>
    )
}

export default ReadyMadeConfirmOrders